.dashboardRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 240px !important;
  font-family: "Poppins", sans-serif;
}

.dashboardMasterContainer {
  margin: 50px 70px !important;
}

.dashboardLabel {
  font-size: 27px !important;
  font-weight: 600;
}

.dashboardContainerA {
  display: flex;
  flex-direction: row;
  margin-top: 30px !important;
  height: 100%;
}

/*       CONTAINER A1       */

.dashboardContainerA1 {
  border: 1px solid #c4c4c4;
  border-radius: 5px !important;
  overflow: hidden !important;
}

.dashboardContainerA1Label {
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  color: white !important;
  background-color: #256141 !important;
  padding: 15px 25px !important;
  font-size: 16px !important;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 1px;
}

.dashboardContainerA1Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 15px;
}

.dashboardContainerA1Inside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboardContainerA1Inside:hover {
  background-color: #e4e3e3 !important;
}

.dashboardContainerA1Inside:not(:nth-child(4n + 1)) {
  margin-left: 20px !important;
}

.dashboardContainerA1InsideLabel {
  font-size: 20px !important;
  font-weight: 600 !important;
  cursor: pointer;
}

.dashboardContainerA1InsideLabel2 {
  font-size: 40px !important;
  font-weight: 500 !important;
  color: #dd1616 !important;
  cursor: pointer;
}

.dashboardContainerA1InsideLabel3 {
  font-size: 40px !important;
  font-weight: 500 !important;
  color: #a4a4a4 !important;
  cursor: pointer;
}

.dashboardContainerA1InsideButton {
  width: 100% !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  text-transform: capitalize !important;
}

/*       CONTAINER A2       */

.dashboardContainerA2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 20px;
}

.dashboardContainerA2Content1 {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  height: 100%;
}

.dashboardContainerA2Content1Label {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.dashboardContainerA2Content1Label2 {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #256141 !important;
  margin-top: 20px !important;
}

.dashboardContainerA2Content1Img {
  height: 90px !important;
  width: 190px !important;
}

.dashboardContainerA2Content1Divider {
  margin: 0 40px;
  border-left: 1px solid #c4c4c4;
  height: 90px;
}

.dashboardContainerA2Content1Count {
  font-size: 40px !important;
  font-weight: 500 !important;
}

.dashboardContainerA2Content1Acc {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.dashboardContainerA2Content2 {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
}

/*       CONTAINER B       */

.dashboardContainerB {
  display: flex;
  flex-direction: row;
  margin-top: 20px !important;
  height: 100%;
  width: 75em;
}

.dashboardContainerB1 {
  margin-right: 20px;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
}

.dashboardContainerB1FormControl {
  margin-left: 220px !important;
  width: 150px !important;
}

.dashboardContainerB1FormControl Select {
  height: 20px !important;
  font-size: 15px;
}

.dashboardContainerB1ContentCount {
  margin-top: 60px !important;
  margin-bottom: 30px !important;
  font-size: 40px !important;
  font-weight: 500 !important;
}

.dashboardContainerB2 {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
}

.dashboardContainerB2FormControl {
  margin-left: 70px !important;
  width: 150px !important;
}

.dashboardContainerB2FormControl Select {
  height: 20px !important;
  font-size: 15px;
}

.dashboardContainerB2ContentCount {
  margin-top: 60px !important;
  margin-bottom: 30px !important;
  font-size: 40px !important;
  font-weight: 500 !important;
  color: #256141 !important;
}

@media only screen and (max-width: 1500px) {
  .dashboardLabel {
    font-size: 21px !important;
  }

  .dashboardContainerA {
    margin-top: 20px !important;
  }

  /*       CONTAINER A1       */

  .dashboardContainerA1 {
    width: 75em;
  }

  .dashboardContainerA1Label {
    font-size: 14px !important;
  }

  .dashboardContainerA1Inside {
    width: 210px !important;
    padding: 20px 40px;
  }

  .dashboardContainerA1InsideLabel {
    font-size: 16px !important;
  }

  .dashboardContainerA1InsideLabel2 {
    font-size: 36px !important;
  }

  .dashboardContainerA1InsideLabel3 {
    font-size: 36px !important;
  }

  /*       CONTAINER A2       */

  .dashboardContainerA2Content1 {
    padding: 15px;
  }

  .dashboardContainerA2Content1Label {
    font-size: 12px !important;
  }

  .dashboardContainerA2Content1Label2 {
    font-size: 12px !important;
  }

  .dashboardContainerA2Content1Img {
    height: 70px !important;
    width: 160px !important;
  }

  .dashboardContainerA2Content1Divider {
    margin: 0 30px;
  }

  .dashboardContainerA2Content1Count {
    font-size: 36px !important;
  }

  .dashboardContainerA2Content1Acc {
    font-size: 12px !important;
  }

  .dashboardContainerA2Content2 {
    padding: 15px;
  }

  /*       CONTAINER B       */

  .dashboardContainerB {
    width: 71.9em;
  }

  .dashboardContainerB1FormControl {
    margin-left: 250px !important;
    width: 110px !important;
  }

  .dashboardContainerB1FormControl Select {
    padding: 10px !important;
    height: 15px !important;
    font-size: 12px;
  }

  .dashboardContainerB1ContentCount {
    margin-top: 50px !important;
    font-size: 36px !important;
  }

  .dashboardContainerB2FormControl {
    margin-left: 150px !important;
    width: 100px !important;
  }

  .dashboardContainerB2FormControl Select {
    padding: 10px !important;
    height: 15px !important;
    font-size: 12px;
  }

  .dashboardContainerB2ContentCount {
    margin-top: 50px !important;
    font-size: 36px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .dashboardMasterContainer {
    margin: 30px 30px !important;
  }

  .dashboardLabel {
    font-size: 17px !important;
  }

  .dashboardContainerA {
    margin-top: 15px !important;
  }

  /*       CONTAINER A1       */

  .dashboardContainerA1 {
    width: 50em;
  }

  .dashboardContainerA1Label {
    padding: 15px 25px !important;
    font-size: 12px !important;
  }

  .dashboardContainerA1Content {
    padding: 20px 30px;
  }

  .dashboardContainerA1Inside {
    width: 150px !important;
    padding: 10px 30px;
  }

  .dashboardContainerA1Inside:not(:nth-child(4n + 1)) {
    margin-left: 10px !important;
  }

  .dashboardContainerA1InsideLabel {
    font-size: 12px !important;
  }

  .dashboardContainerA1InsideLabel2 {
    font-size: 28px !important;
  }

  .dashboardContainerA1InsideLabel3 {
    font-size: 28px !important;
  }

  .dashboardContainerA1InsideButton {
    font-size: 12px !important;
  }

  /*       CONTAINER A2       */

  .dashboardContainerA2 {
    margin-left: 10px;
  }

  .dashboardContainerA2Content1 {
    padding: 10px;
    margin-bottom: 10px;
  }

  .dashboardContainerA2Content1Label {
    font-size: 12px !important;
  }

  .dashboardContainerA2Content1Label2 {
    font-size: 12px !important;
    margin-top: 20px !important;
  }

  .dashboardContainerA2Content1Img {
    height: 60px !important;
    width: 130px !important;
  }

  .dashboardContainerA2Content1Divider {
    margin: 0 30px;
  }

  .dashboardContainerA2Content1Count {
    font-size: 28px !important;
  }

  .dashboardContainerA2Content1Acc {
    font-size: 9px !important;
  }

  .dashboardContainerA2Content2 {
    padding: 10px;
  }

  /*       CONTAINER B       */

  .dashboardContainerB {
    margin-top: 10px !important;
    width: 50em;
  }

  .dashboardContainerB1 {
    margin-right: 10px;
    padding: 15px;
  }

  .dashboardContainerB1FormControl {
    margin-left: 130px !important;
    width: 90px !important;
  }

  .dashboardContainerB1FormControl Select {
    padding: 5px 5px 5px 10px !important;
    height: 20px !important;
    font-size: 10px;
  }

  .dashboardContainerB1ContentCount {
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    font-size: 32px !important;
  }

  .dashboardContainerB2 {
    padding: 15px;
  }

  .dashboardContainerB2FormControl {
    margin-left: 50px !important;
    width: 130px !important;
  }

  .dashboardContainerB2FormControl Select {
    padding: 5px 5px 5px 10px !important;
    height: 20px !important;
    font-size: 10px;
  }

  .dashboardContainerB2ContentCount {
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    font-size: 32px !important;
  }
}
