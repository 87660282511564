.root {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  margin-left: 240px;
  height: 100%;
}

.detailsTable {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: black;
  border-collapse: collapse;
  text-transform: capitalize;
}

.detailsTable tr {
  padding-left: 20px;
  height: 68px;
}

.detailsTable td {
  border-bottom: 1px solid #dadada !important;
}

.detailsTableTitle {
  color: black !important;
  font-size: 16px !important;
}
