@media only screen and (min-width: 1280px) {
  .root {
    flex-grow: 1;
    width: 720px;
    margin-left: 550px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: "center";
    font-family: "Poppins", sans-serif;
  }

  .labels {
    font-size: 13px;
    font-weight: 600;
  }

  .labels1 {
    font-size: 22px;
    font-weight: bold;
  }

  .labels2 {
    font-size: 17px;
    font-weight: 600;
  }

  .textboxs {
    width: 150px;
    font-size: 13px;
    text-transform: capitalize;
  }

  .textboxs1 {
    width: 115px;
    font-size: 13px;
  }

  .textboxs1Text {
    font-size: 13px;
  }

  .textboxs2 {
    width: 336px;
    font-size: 13px;
  }

  .selectboxs {
    width: 133px;
    font-size: 13px;
  }

  .addButton {
    margin-left: 30px;
    width: 130px;
    height: 40px;
    color: #ffffff;
    background-color: #c8932a;
    text-transform: capitalize;
    font-size: 12px;
  }

  .addButton:hover {
    background-color: #a67717;
  }

  .MuiCircularProgress-colorPrimary {
    color: "#1AB071" !important;
  }

  .alertWidth {
    width: 347px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1920px) {
  .root {
    flex-grow: 1;
    width: 1100px;
    margin-left: 790px;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: "center";
    font-family: "Poppins", sans-serif;
  }

  .labels {
    font-size: 16px;
    font-weight: 600;
  }

  .labels1 {
    font-size: 29px;
    font-weight: bold;
  }

  .labels2 {
    font-size: 20px;
    font-weight: 600;
  }

  .textboxs {
    width: 242px;
    font-size: 16px;
    text-transform: capitalize;
  }

  .textboxs1 {
    width: 202px;
    font-size: 16px;
  }

  .textboxs1Text {
    font-size: 16px;
  }

  .textboxs2 {
    width: 522px;
    font-size: 16px;
  }

  .selectboxs {
    width: 223px;
    font-size: 16px;
  }

  .addButton {
    margin-left: 70px;
    width: 181px;
    height: 50px;
    color: #ffffff;
    background-color: #c8932a;
    text-transform: capitalize;
    font-size: 14px;
  }

  .addButton:hover {
    background-color: #a67717;
  }

  .MuiCircularProgress-colorPrimary {
    color: "#1AB071" !important;
  }

  .alertWidth {
    width: 531px;
    margin-top: 20px;
  }
}
