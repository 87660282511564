.termsAndConditionRoot {
  flex-grow: 1;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.termsAndConditionContainer {
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 45%;
}

.termsAndConditionHeader {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.termsAndConditionTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.termsAndConditionTextBox {
  padding: 15px !important;
  font-size: 16px !important;
}

.termsAndConditionDescription {
  font-size: 16px !important;
}

.termsAndConditionCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.termsAndConditionAddButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.termsAndConditionAddButton:hover {
  background-color: #18422b !important;
}

.termsAndConditionLoading {
  float: right !important;
  margin-right: 50px;
}

.termsAndConditionToolbar {
  border: none !important;
}

.termsAndConditionWrapper {
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
}

.termsAndConditionWrapper:hover {
  border: 1px solid black !important;
  border-radius: 5px !important;
}

.termsAndConditionWrapper:focus-within {
  border: 2px solid #c8932a !important;
}

.termsAndConditionWrapperError {
  border: 1px solid red !important;
  border-radius: 5px !important;
}

.termsAndConditionWrapperError:focus-within {
  border: 2px solid red !important;
  border-radius: 5px !important;
}

.termsAndConditionEditor {
  /* border: 1px solid lightgray !important;
    border-radius: 5px !important; */
  min-height: 250px !important;
  padding: 0 10px !important;
  cursor: text !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif;
}

/* .termsAndConditionEditor:hover {
    border: 1px solid black !important;
    border-radius: 5px !important;
}

.termsAndConditionEditor:focus-within {
    border: 2px solid #C8932A !important;
} */

@media only screen and (max-width: 1500px) {
  .termsAndConditionContainer {
    margin-left: 22%;
    width: 51%;
  }

  .termsAndConditionHeader {
    font-size: 24px;
  }

  .termsAndConditionTitle {
    font-size: 14px;
  }

  .termsAndConditionTextBox {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .termsAndConditionDescription {
    font-size: 14px !important;
  }

  .termsAndConditionCancelButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }

  .termsAndConditionAddButton {
    height: 40px;
    width: 120px;
    font-size: 13px !important;
  }

  .termsAndConditionEditor {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .termsAndConditionRoot {
    margin-left: 260px !important;
    margin-top: -20px !important;
  }

  .termsAndConditionContainer {
    margin-left: 21%;
    width: 55%;
    /* border: 1px solid black; */
  }

  .termsAndConditionHeader {
    font-size: 22px;
  }

  .termsAndConditionTitle {
    font-size: 12px;
  }

  .termsAndConditionTextBox {
    padding: 12px !important;
    font-size: 12px !important;
  }

  .termsAndConditionDescription {
    font-size: 12px !important;
  }

  .termsAndConditionCancelButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .termsAndConditionAddButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .termsAndConditionEditor {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .termsAndConditionRoot {
    margin-left: 0 !important;
    margin-top: -20px !important;
  }
}
