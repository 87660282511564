.loginBg {
  background: rgb(37, 97, 65);
  height: 100vh;
  width: 100%;
  background-image: url("../../../images/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login {
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  background: white;
  position: relative;
  top: 150px;
  padding: 50px;
  width: 30%;
}

.login img {
  height: 50px;
  margin-bottom: 20px;
}

.login button {
  color: white;
  font-weight: bold;
  border-radius: 5px;
  text-transform: none;
}

.login .signWith {
  color: #9f9f9f;
}

.btnGoogle {
  display: inline;
  border: 1px solid #9f9f9f;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #9f9f9f;
  cursor: pointer;
}

.btnGoogle img {
  height: 20px;
  margin: -3px 10px -3px 0px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex .border {
  background: #9f9f9f;
  width: 70%;
  height: 1px;
}

.latestNews {
  background: white;
  height: 140px;
  margin-bottom: 10px;
  display: flex;
  overflow: hidden;
}

.latest-news img {
  height: 140px;
}

.latest-news .text {
  padding: 20px;
  height: 140px;
  font-size: 12px;
}

._title {
  color: #0d242c;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

._subtitle {
  color: #444444;
  font-size: 16px;
  font-weight: 600;
}

.link {
  color: #c8932a !important;
  text-decoration-line: underline !important;
  cursor: pointer;
}

@media only screen and (max-width: 1920px) {
  .login {
    top: 120px;
    width: 30%;
  }
}

@media only screen and (max-width: 1366px) {
  .login {
    top: 60px;
    width: 30%;
  }
}

@media only screen and (max-width: 982px) {
  .login {
    top: 100px;
    width: 40%;
  }
}
