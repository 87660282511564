.categoriesAddRoot {
  flex-grow: 1;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.categoriesAddContainer {
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 45%;
  /* border: 1px solid black; */
}

.categoriesAddHeader {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.categoriesAddTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.categoriesAddTextBox {
  padding: 20px !important;
  font-size: 16px !important;
}

.categoriesAddDescription {
  font-size: 16px !important;
}

.categoriesAddCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.categoriesAddAddButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.categoriesAddAddButton:hover {
  background-color: #18422b !important;
}

.categoriesAddLoading {
  float: right !important;
  margin-right: 50px;
}

@media only screen and (max-width: 1500px) {
  .categoriesAddContainer {
    margin-left: 22%;
    width: 51%;
  }

  .categoriesAddHeader {
    font-size: 24px;
  }

  .categoriesAddTitle {
    font-size: 14px;
  }

  .categoriesAddTextBox {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .categoriesAddDescription {
    font-size: 14px !important;
  }

  .categoriesAddCancelButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }

  .categoriesAddAddButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .categoriesAddRoot {
    margin-left: 260px !important;
    margin-top: -20px !important;
  }

  .categoriesAddContainer {
    margin-left: 21%;
    width: 55%;
    /* border: 1px solid black; */
  }

  .categoriesAddHeader {
    font-size: 22px;
  }

  .categoriesAddTitle {
    font-size: 12px;
  }

  .categoriesAddTextBox {
    padding: 12px !important;
    font-size: 12px !important;
  }

  .categoriesAddDescription {
    font-size: 12px !important;
  }

  .categoriesAddCancelButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .categoriesAddAddButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .categoriesAddRoot {
    margin-left: 0 !important;
    margin-top: -20px !important;
  }
}
