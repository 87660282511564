.companyEditRoot {
  flex-grow: 1;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.companyEditContainer {
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 45%;
}

.companyEditHeader {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.companyEditTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.companyEditUpload {
  padding: 15px;
  font-size: 16px !important;
  border-radius: 4px !important;
  border: 1px solid lightgrey;
}

.companyEditUpload:hover {
  border-radius: 4px !important;
  border: 1px solid black;
}

.companyEditUpload:focus {
  outline: none !important;
  border: 2px solid #c8932a;
}

.companyEditTextBox {
  padding: 20px !important;
  font-size: 16px !important;
}

.companyEditDescription {
  font-size: 16px !important;
}

.companyEditCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.companyEditEditButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.companyEditEditButton:hover {
  background-color: #18422b !important;
}

.companyEditLoading {
  float: right !important;
  margin-right: 50px;
}

.companyEditUploadIconSize {
  font-size: 20px !important;
}

.companyEditUploadChip {
  margin-top: 10px;
  background-color: #256141 !important;
  font-size: 14px !important;
}

@media only screen and (max-width: 1500px) {
  .companyEditContainer {
    margin-left: 22%;
    width: 51%;
  }

  .companyEditHeader {
    font-size: 24px;
  }

  .companyEditTitle {
    font-size: 14px;
  }

  .companyEditUpload {
    padding: 10px;
    font-size: 14px !important;
  }

  .companyEditTextBox {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .companyEditDescription {
    font-size: 14px !important;
  }

  .companyEditCancelButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }

  .companyEditEditButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .companyEditRoot {
    margin-left: 260px !important;
    margin-top: -20px !important;
  }

  .companyEditContainer {
    margin-left: 21%;
    width: 55%;
  }

  .companyEditHeader {
    font-size: 22px;
  }

  .companyEditTitle {
    font-size: 12px;
  }

  .companyEditUpload {
    padding: 9px;
    font-size: 12px !important;
  }

  .companyEditTextBox {
    padding: 12px !important;
    font-size: 12px !important;
  }

  .companyEditDescription {
    font-size: 12px !important;
  }

  .companyEditCancelButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .companyEditEditButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .companyEditUploadIconSize {
    font-size: 17px !important;
  }

  .companyEditUploadChip {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .companyEditRoot {
    margin-left: 0 !important;
    margin-top: -20px !important;
  }
}
