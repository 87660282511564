.root {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  margin-left: 240px;
  height: 100%;
}

.containerLeft {
  height: auto;
  min-height: 87vh;
  width: 50%;
  padding: 18px 55px;
  box-shadow: 1px -3px 4px 4px #ccc;
}

.breadcrumbsSize {
  font-size: 12px !important;
  text-transform: capitalize;
}

.clientNameLabel {
  font-size: 29px;
  font-weight: bold;
  text-transform: capitalize;
}

.clientEmailLabel {
  margin-top: 4px;
  font-size: 14px;
}

.detailsTable {
  width: 100%;
  margin-top: 26px;
  font-size: 14px;
  text-align: right;
  padding-bottom: 10px;
}

.detailsTable td:first-child {
  font-weight: bold;
  text-align: left;
}

.tabBottomColor {
  background: #256141 !important;
}

.tabTextActive {
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: capitalize !important;
  font-weight: bold !important;
  color: #256141 !important;
  height: 60px;
}

.tabText {
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: capitalize !important;
  font-weight: bold !important;
  height: 60px;
}

.tabItemsContainerActive {
  padding: 25px 18px;
  border: 2px solid #1ab071 !important;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.tabItemsContainerActive:hover {
  background-color: #c4c4c4a2;
}

.tabItemsContainer {
  padding: 25px 18px;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.tabItemsContainer:hover {
  background-color: #c4c4c4a2;
}

.iconBackground {
  padding-top: 10px;
  margin-right: 19px;
  background: #256141;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconSize {
  font-size: 25px !important;
  color: white;
}

.itemTitle {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.itemDescription {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

/* CONTENT RIGHT */

.containerRight {
  height: 100%;
  width: 100%;
  padding: 47px 80px;
  /* border: 1px solid black; */
}

.content2Title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 60px;
}

.validatedLabel {
  width: 100%;
  background-color: #256141;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 8px 0;
  margin-bottom: 20px;
}

.assignManagerButton {
  margin: 10px 0 20px 0 !important;
  height: 50px;
  width: 100%;
  background: #256141 !important;
  color: white !important;
  border-radius: 5px !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.assignManagerButton:hover {
  background-color: #18442d !important;
}

.assignManagerButton2 {
  margin: 10px 0 20px 0 !important;
  height: 50px;
  width: 100%;
  border: 1px solid #256141 !important;
  background: white !important;
  color: #256141 !important;
  border-radius: 5px !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.assignManagerButton2:hover {
  background-color: rgb(207, 196, 196) !important;
}

.content2IconBackground {
  padding-top: 10px;
  margin-right: 19px;
  background: #256141;
  width: 58px;
  height: 58px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content2IconSize {
  font-size: 38px !important;
  color: white;
}

.content2ItemTitle {
  font-size: 29px;
  font-weight: 600;
}

.content2ItemDescription {
  font-size: 14px;
  font-weight: 400;
}

.accordionTitle {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.accordionTable {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: black;
  border-collapse: collapse;
  text-transform: capitalize;
}

.accordionTable tr {
  padding-left: 20px;
  height: 68px;
}

.accordionTable td {
  border-bottom: 1px solid #dadada !important;
}

.accordionTableTitle {
  color: black !important;
  font-size: 16px !important;
}

.containerRight td:first-child {
  width: 440px;
  padding-right: 30px;
  font-weight: 600;
  color: #a4a4a4;
}

.btnNextPrev {
  height: 41px;
  background-color: #eaeaea !important;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 600 !important;
  width: 100%;
}

.btnNextPrev:hover {
  background-color: #b4adad !important;
}

.btnDecline {
  margin-top: 34px !important;
  height: 50px;
  width: 181px;
  color: #ed5555 !important;
  border: 1px solid #ed5555 !important;
  border-radius: 5px;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.btnDecline:hover {
  background-color: #d8777781 !important;
}

.btnApprove {
  height: 50px;
  width: 181px;
  background: #256141 !important;
  color: white !important;
  border-radius: 5px;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.btnApprove:hover {
  background-color: #18442d !important;
}

.btnApprove:disabled {
  pointer-events: auto;
  background: #a4a4a4 !important;
}

.btnSpanApprove {
  float: right;
  margin-top: 34px !important;
}

.riskContainer {
  padding: 18px;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.riskIconBackgroundCon {
  padding-top: 12px;
  margin-right: 19px;
  background: #507be9;
  width: 44px;
  height: 44px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.riskIconBackgroundMod {
  padding-top: 10px;
  margin-right: 19px;
  background: #c8932a;
  width: 44px;
  height: 44px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.riskIconBackgroundAgg {
  padding-top: 10px;
  margin-right: 19px;
  background: #ed5555;
  width: 44px;
  height: 44px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attachmentLabel {
  font-size: 20px;
  font-weight: 600;
}

.attachmentDiv {
  margin-top: 15px;
  width: 280px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  cursor: pointer;
  word-break: break-all;
}

.attachmentIcon {
  height: 35px;
  width: 30px;
  margin-right: 18px;
  cursor: pointer;
}

.dialogIconBackground {
  margin: 25px 25px 10px 25px;
  background: #256141 !important;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogIconBackgroundDecline {
  margin: 25px 25px 10px 25px;
  background: #ed5555 !important;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogIcon {
  margin-top: 5px;
  font-size: 40px !important;
  color: white !important;
}

.dialogTitle {
  font-size: 24px;
  font-weight: 600;
}

.dialogClientName {
  font-size: 24px;
  font-weight: 600;
  color: #256141 !important;
  text-transform: capitalize;
}

.dialogDescription {
  font-size: 14px;
  font-weight: 400;
}

.dialogApproveButton {
  margin: 0 25px 25px 25px !important;
  background: #256141 !important;
  color: white !important;
  text-transform: capitalize !important;
  height: 50px;
  width: 380px !important;
  font-weight: 600 !important;
}

.dialogApproveButton:hover {
  background-color: #18442d !important;
}

.dialogLegacyButton {
  margin: -15px 25px 20px 25px !important;
  height: 50px;
  color: #256141 !important;
  border: 1px solid #256141 !important;
  text-transform: capitalize !important;
  font-size: 12px;
  font-weight: 600 !important;
}

.dialogCancelButton {
  margin: -15px 25px 20px 25px !important;
  height: 50px;
  color: #ed5555 !important;
  border: 1px solid #ed5555 !important;
  text-transform: capitalize !important;
  font-size: 14px;
  width: 380px !important;
  font-weight: 600 !important;
}

.dialogCancelButton:hover {
  background-color: #d8777781 !important;
}

.dialogDropdown {
  width: 380px;
}

.dialogDropdownSelect {
  font-size: 14px !important;
  height: 50px !important;
}

.dialogLoading {
  margin-left: 45%;
}

.imagePreview {
  width: 40%;
  height: 40%;
  object-fit: scale-down;
}

.transContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
}

.pendingApprovedContainer {
  height: 450px;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.pendingApprovedContainer::-webkit-scrollbar {
  width: 0 !important;
}

.noInvestmentTab {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.noInvestmentContent {
  font-size: 20px;
  font-weight: 600;
}

@media only screen and (max-width: 1500px) {
  .root {
    margin-left: 260px;
  }

  .containerLeft {
    width: 50%;
    padding: 18px 35px;
  }

  .breadcrumbsSize {
    font-size: 11px !important;
  }

  .clientNameLabel {
    font-size: 23px;
  }

  .clientEmailLabel {
    margin-top: 4px;
    font-size: 11px;
  }

  .detailsTable {
    margin-top: 26px;
    font-size: 11px;
  }

  .tabTextActive {
    font-size: 12px !important;
    height: 20px !important;
  }

  .tabText {
    font-size: 12px !important;
    height: 20px !important;
  }

  .tabItemsContainerActive {
    padding: 18px 18px;
  }

  .tabItemsContainer {
    padding: 18px 18px;
  }

  .iconBackground {
    padding-top: 9px;
    margin-right: 14px;
    width: 40px;
    height: 40px;
  }

  .iconSize {
    font-size: 22px !important;
  }

  .itemTitle {
    font-size: 11px;
  }

  .itemDescription {
    font-size: 11px;
  }

  /* CONTENT RIGHT */

  .containerRight {
    padding: 37px 50px;
  }

  .content2Title {
    margin-bottom: 37px;
  }

  .validatedLabel {
    font-size: 14px;
  }

  .assignManagerButton {
    height: 40px;
    font-size: 12px !important;
  }

  .assignManagerButton2 {
    height: 40px;
    font-size: 12px !important;
  }

  .content2IconBackground {
    padding-top: 9px;
    margin-right: 19px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
  }

  .content2IconSize {
    font-size: 32px !important;
    color: white;
  }

  .content2ItemTitle {
    font-size: 20px;
  }

  .content2ItemDescription {
    font-size: 12px;
  }

  .accordionTitle {
    font-size: 14px;
  }

  .accordionTable {
    font-size: 11px;
  }

  .accordionTable tr {
    height: 48px;
  }

  .accordionTableTitle {
    font-size: 11px !important;
  }

  .containerRight td:first-child {
    width: 310px;
  }

  .btnNextPrev {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    height: 35px;
    font-size: 12px !important;
  }

  .btnDecline {
    margin-top: 30px !important;
    height: 40px;
    width: 141px;
    font-size: 12px !important;
  }

  .btnApprove {
    margin-top: 30px !important;
    height: 40px;
    width: 141px;
    font-size: 12px !important;
  }

  .riskContainer {
    padding: 14px !important;
  }

  .riskIconBackgroundCon {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .riskIconBackgroundMod {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .riskIconBackgroundAgg {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .attachmentLabel {
    font-size: 16px;
  }

  .attachmentDiv {
    margin-top: 10px;
    width: 220px;
    padding: 14px;
  }

  .attachmentIcon {
    height: 30px;
    width: 25px;
  }

  .dialogIconBackground {
    margin: 25px 25px 10px 25px;
    height: 50px;
    width: 50px;
  }

  .dialogIconBackgroundDecline {
    margin: 25px 25px 10px 25px;
    height: 50px;
    width: 50px;
  }

  .dialogIcon {
    margin-top: 8px;
    font-size: 30px !important;
  }

  .dialogTitle {
    font-size: 20px;
  }

  .dialogClientName {
    font-size: 20px;
  }

  .dialogDescription {
    font-size: 12px;
  }

  .dialogApproveButton {
    height: 40px;
    width: 340px !important;
    font-size: 12px !important;
  }

  .dialogCancelButton {
    height: 40px;
    font-size: 12px;
    width: 340px !important;
  }

  .dialogDropdown {
    width: 340px;
  }

  .dialogDropdownSelect {
    font-size: 12px !important;
    height: 40px !important;
  }

  .dialogLoading {
    margin-left: 43%;
  }

  .pendingApprovedContainer {
    height: 380px;
  }
}

@media only screen and (max-width: 1366px) {
  .root {
    margin-top: 0;
    margin-left: 240px;
  }

  .containerLeft {
    width: 34%;
    padding: 18px 25px;
  }

  .breadcrumbsSize {
    font-size: 10px !important;
  }

  .clientNameLabel {
    font-size: 22px;
  }

  .clientEmailLabel {
    margin-top: 4px;
    font-size: 10px;
  }

  .detailsTable {
    margin-top: 26px;
    font-size: 10px;
  }

  .tabTextActive {
    font-size: 11px !important;
    height: 20px !important;
  }

  .tabText {
    font-size: 11px !important;
    height: 20px !important;
  }

  .tabItemsContainerActive {
    padding: 18px 18px;
  }

  .tabItemsContainer {
    padding: 18px 18px;
  }

  .iconBackground {
    padding-top: 10px;
    margin-right: 14px;
    width: 40px;
    height: 40px;
  }

  .iconSize {
    font-size: 22px !important;
  }

  .itemTitle {
    font-size: 11px;
  }

  .itemDescription {
    font-size: 11px;
  }

  /* CONTENT RIGHT */

  .containerRight {
    padding: 37px 50px;
  }

  .content2Title {
    margin-bottom: 37px;
  }

  .validatedLabel {
    font-size: 11px;
  }

  .assignManagerButton {
    height: 35px;
    font-size: 11px !important;
  }

  .assignManagerButton2 {
    height: 35px;
    font-size: 11px !important;
  }

  .content2IconBackground {
    padding-top: 9px;
    margin-right: 19px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
  }

  .content2IconSize {
    font-size: 32px !important;
    color: white;
  }

  .content2ItemTitle {
    font-size: 19px;
  }

  .content2ItemDescription {
    font-size: 11px;
  }

  .accordionTitle {
    font-size: 13px;
  }

  .accordionTable {
    font-size: 10px;
  }

  .accordionTable tr {
    height: 45px;
  }

  .accordionTableTitle {
    font-size: 11px !important;
  }

  .containerRight td:first-child {
    width: 310px;
  }

  .btnNextPrev {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    height: 35px;
    font-size: 11px !important;
  }

  .btnDecline {
    margin-top: 30px !important;
    height: 35px;
    width: 141px;
    font-size: 11px !important;
  }

  .btnApprove {
    margin-top: 30px !important;
    height: 35px;
    width: 141px;
    font-size: 11px !important;
  }

  .riskContainer {
    padding: 14px !important;
  }

  .riskIconBackgroundCon {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .riskIconBackgroundMod {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .riskIconBackgroundAgg {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .attachmentLabel {
    font-size: 14px;
  }

  .attachmentDiv {
    width: 200px;
    padding: 12px;
  }

  .attachmentIcon {
    height: 30px;
    width: 25px;
  }

  .dialogIconBackground {
    margin: 25px 25px 10px 25px;
    height: 50px;
    width: 50px;
  }

  .dialogIconBackgroundDecline {
    margin: 25px 25px 10px 25px;
    height: 50px;
    width: 50px;
  }

  .dialogIcon {
    margin-top: 8px;
    font-size: 30px !important;
  }

  .dialogTitle {
    font-size: 20px;
  }

  .dialogClientName {
    font-size: 20px;
  }

  .dialogDescription {
    font-size: 12px;
  }

  .dialogApproveButton {
    height: 40px;
    width: 340px !important;
    font-size: 12px !important;
  }

  .dialogCancelButton {
    height: 40px;
    font-size: 12px;
    width: 340px !important;
  }

  .dialogDropdown {
    width: 340px;
  }

  .dialogDropdownSelect {
    font-size: 12px !important;
    height: 40px !important;
  }

  .dialogLoading {
    margin-left: 43%;
  }
}

@media only screen and (max-width: 1200px) {
  .root {
    margin-top: 5px;
    margin-left: 240px;
  }

  .containerLeft {
    width: 35%;
  }

  .breadcrumbsSize {
    font-size: 10px !important;
  }

  .clientNameLabel {
    font-size: 20px;
  }

  .clientEmailLabel {
    margin-top: 4px;
    font-size: 10px;
  }

  .detailsTable {
    margin-top: 18px;
    font-size: 10px;
  }

  .tabTextActive {
    width: 10% !important;
    font-size: 10px !important;
    height: 17px !important;
  }

  .tabText {
    width: 10% !important;
    font-size: 10px !important;
    height: 17px !important;
  }

  .tabItemsContainerActive {
    padding: 14px 14px;
  }

  .tabItemsContainer {
    padding: 14px 14px;
  }

  .iconBackground {
    padding-top: 9px;
    margin-right: 14px;
    width: 40px;
    height: 40px;
  }

  .iconSize {
    font-size: 22px !important;
  }

  .itemTitle {
    font-size: 10px;
  }

  .itemDescription {
    font-size: 10px;
  }

  /* CONTENT RIGHT */

  .containerRight {
    padding: 27px 30px;
  }

  .content2Title {
    margin-bottom: 30px;
  }

  .content2IconBackground {
    padding-top: 7px;
    margin-right: 15px;
    width: 40px;
    height: 40px;
  }

  .content2IconSize {
    font-size: 27px !important;
    color: white;
  }

  .content2ItemTitle {
    font-size: 17px;
  }

  .validatedLabel {
    font-size: 11px;
  }

  .assignManagerButton {
    height: 35px;
    font-size: 11px !important;
  }

  .assignManagerButton2 {
    height: 35px;
    font-size: 11px !important;
  }

  .content2ItemDescription {
    font-size: 10px;
  }

  .accordionTitle {
    font-size: 12px;
  }

  .accordionTable {
    font-size: 10px;
  }

  .accordionTable tr {
    height: 40px;
  }

  .accordionTableTitle {
    font-size: 10px !important;
  }

  .containerRight td:first-child {
    width: 230px;
  }

  .btnNextPrev {
    margin-bottom: 20px !important;
    height: 30px;
    font-size: 10px !important;
  }

  .btnDecline {
    margin-top: 20px !important;
    height: 30px;
    width: 131px;
    font-size: 10px !important;
  }

  .btnApprove {
    margin-top: 20px !important;
    height: 30px;
    width: 131px;
    font-size: 10px !important;
  }

  .riskContainer {
    padding: 10px !important;
  }

  .riskIconBackgroundCon {
    padding-top: 9px;
    margin-right: 13px;
    width: 35px;
    height: 35px;
  }

  .riskIconBackgroundMod {
    padding-top: 9px;
    margin-right: 13px;
    width: 35px;
    height: 35px;
  }

  .riskIconBackgroundAgg {
    padding-top: 9px;
    margin-right: 13px;
    width: 35px;
    height: 35px;
  }

  .dialogIconBackground {
    margin: 25px 25px 10px 25px;
    height: 45px;
    width: 45px;
  }

  .dialogIconBackgroundDecline {
    margin: 25px 25px 10px 25px;
    height: 45px;
    width: 45px;
  }

  .dialogIcon {
    margin-top: 8px;
    font-size: 25px !important;
  }

  .dialogTitle {
    font-size: 17px;
  }

  .dialogClientName {
    font-size: 17px;
  }

  .dialogDescription {
    font-size: 10px;
  }

  .dialogApproveButton {
    height: 35px;
    width: 300px !important;
    font-size: 10px !important;
  }

  .dialogCancelButton {
    height: 35px;
    font-size: 10px !important;
    width: 300px !important;
  }

  .dialogDropdown {
    width: 300px;
  }

  .dialogDropdownSelect {
    font-size: 10px !important;
    height: 35px !important;
  }

  .pendingApprovedContainer {
    height: 350px;
  }
}

@media only screen and (max-width: 1060px) {
  .root {
    margin-left: 0;
  }
}
