@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Poppins", monospace;
}

.cms-main-content {
  flex-grow: 1;
  margin-left: 280px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 1366px) {
  .cms-main-content {
    margin-left: 270px !important;
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .cms-main-content {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 720px) {
  .r-title {
    font-size: 28px !important;
    font-weight: 600;
    margin: 0;
  }
}

.r-title-container {
  padding-top: 15%;
}

.r-title {
  font-size: 48px;
  font-weight: 600;
  margin: 20px;
}

.text-center {
  text-align: center !important;
}

.r-subtitle {
  font-size: 20px;
  font-weight: normal;
  margin: 20px;
}

.mobile-display .form-control {
  border: 1px solid #cacaca00 !important;
  cursor: text !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}
.mobile-display .selected-flag {
  padding: 0px !important;
}

.mobile-display .selected-flag .arrow {
  border-top: 4px solid rgba(85, 85, 85, 0) !important;
}
