.fundRatesUploadRoot {
  flex-grow: 1;
  margin-top: 10px;
  margin-left: 260px;
}

.fundRatesUploadContainer {
  display: flex;
  min-height: 85vh;
  flex-direction: column;
  padding: 30px 300px;
  /* border: 1px solid black; */
}

.fundRatesUploadTitle {
  font-size: 29px;
  font-weight: 600;
}

.fundRatesUploadNotice {
  font-size: 17px;
}

.fundRatesUploadNotice2 {
  margin-top: 30px;
  font-size: 19px;
  font-weight: 600;
}

.fundRatesUploadLabels {
  font-size: 13px;
  color: #9f9f9f;
}

.fundRatesUploadIconSize {
  font-size: 20px;
}

.fundRatesUploadChip {
  margin-top: 20px;
  background-color: #256141 !important;
  font-size: 14px;
}

.fundRatesUploadCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundRatesUploadAddButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.fundRatesUploadAddButton:hover {
  background-color: #18422b !important;
}

.fundRatesUploadIconBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ed5555 !important;
  border-radius: 100% !important;
  height: 65px;
  width: 65px;
}

.fundRatesUploadIcon {
  margin-top: 9px;
  color: white !important;
  font-size: 40px !important;
}

.fundRatesUploadDialogTitle {
  font-size: 20px !important;
  font-weight: 600;
}

.fundRatesUploadDialogName {
  font-size: 24px !important;
  font-weight: 600;
  color: #256141 !important;
}

.fundRatesUploadDialogDeleteBtn {
  background-color: #ed5555 !important;
  color: white !important;
  height: 55px;
  margin: 0 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundRatesUploadDialogDeleteBtn:hover {
  background-color: #bd3b3b !important;
}

.fundRatesUploadLoading {
  float: right !important;
  margin-right: 40px;
}

.fundRatesUploadDatePickerInput {
  width: 250px !important;
}

.fundRatesUploadDatePicker {
  padding: 20px !important;
  font-size: 14px !important;
  height: 10px !important;
}

@media only screen and (max-width: 1500px) {
  .fundRatesUploadContainer {
    padding: 50px 200px;
  }

  .fundRatesUploadTitle {
    font-size: 22px;
  }

  .fundRatesUploadNotice {
    font-size: 15px;
  }

  .fundRatesUploadNotice2 {
    font-size: 17px;
  }

  .fundRatesUploadLabels {
    font-size: 11px;
  }

  .fundRatesUploadChip {
    font-size: 12px;
  }

  .fundRatesUploadCancelButton {
    height: 40px;
    width: 110px;
    font-size: 12px !important;
  }

  .fundRatesUploadAddButton {
    height: 40px;
    width: 110px;
    font-size: 12px !important;
  }

  .fundRatesUploadIconBackground {
    height: 55px;
    width: 55px;
  }

  .fundRatesUploadIcon {
    margin-top: 8px;
    font-size: 35px !important;
  }

  .fundRatesUploadDialogTitle {
    font-size: 17px !important;
  }

  .fundRatesUploadDialogName {
    font-size: 21px !important;
  }

  .fundRatesUploadDialogDeleteBtn {
    height: 50px;
    font-size: 12px !important;
  }

  .fundRatesUploadDatePicker {
    padding: 17px !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .fundRatesUploadRoot {
    margin-top: 0;
    margin-left: 240px;
  }

  .fundRatesUploadContainer {
    padding: 50px 200px;
  }

  .fundRatesUploadTitle {
    font-size: 20px;
  }

  .fundRatesUploadNotice {
    font-size: 13px;
  }

  .fundRatesUploadNotice2 {
    font-size: 15px;
  }

  .fundRatesUploadLabels {
    font-size: 11px;
  }

  .fundRatesUploadIconSize {
    font-size: 18px;
  }

  .fundRatesUploadChip {
    font-size: 10px;
  }

  .fundRatesUploadCancelButton {
    height: 35px;
    width: 110px;
    font-size: 11px !important;
  }

  .fundRatesUploadAddButton {
    height: 35px;
    width: 110px;
    font-size: 11px !important;
  }

  .fundRatesUploadIconBackground {
    height: 55px;
    width: 55px;
  }

  .fundRatesUploadIcon {
    margin-top: 8px;
    font-size: 35px !important;
  }

  .fundRatesUploadDialogTitle {
    font-size: 17px !important;
  }

  .fundRatesUploadDialogName {
    font-size: 21px !important;
  }

  .fundRatesUploadDialogDeleteBtn {
    height: 50px;
    font-size: 12px !important;
  }

  .fundRatesUploadDatePicker {
    padding: 14px !important;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .fundRatesUploadRoot {
    margin-left: 0;
  }
}
