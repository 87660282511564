@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
.visible{
  visibility: visible;
}

.static{
  position: static;
}

.fixed{
  position: fixed;
}

.absolute{
  position: absolute;
}

.relative{
  position: relative;
}

.top-0{
  top: 0px;
}

.right-0{
  right: 0px;
}

.bottom-0{
  bottom: 0px;
}

.left-0{
  left: 0px;
}

.col-auto{
  grid-column: auto;
}

.m-0{
  margin: 0px;
}

.mt-4{
  margin-top: 1rem;
}

.mt-8{
  margin-top: 2rem;
}

.mt-12{
  margin-top: 3rem;
}

.mb-2{
  margin-bottom: 0.5rem;
}

.block{
  display: block;
}

.inline{
  display: inline;
}

.flex{
  display: flex;
}

.inline-flex{
  display: inline-flex;
}

.table{
  display: table;
}

.grid{
  display: grid;
}

.hidden{
  display: none;
}

.w-full{
  width: 100%;
}

.max-w-lg{
  max-width: 32rem;
}

.max-w-xl{
  max-width: 36rem;
}

.flex-1{
  flex: 1 1 0%;
}

.transform{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse{
  50%{
    opacity: .5;
  }
}

@keyframes pulse{
  50%{
    opacity: .5;
  }
}

@-webkit-keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.flex-col{
  flex-direction: column;
}

.items-center{
  align-items: center;
}

.justify-end{
  justify-content: flex-end;
}

.justify-center{
  justify-content: center;
}

.gap-2{
  gap: 0.5rem;
}

.gap-4{
  gap: 1rem;
}

.gap-6{
  gap: 1.5rem;
}

.self-center{
  align-self: center;
}

.rounded{
  border-radius: 0.25rem;
}

.border{
  border-width: 1px;
}

.bg-black{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.p-0{
  padding: 0px;
}

.p-3{
  padding: 0.75rem;
}

.p-8{
  padding: 2rem;
}

.p-16{
  padding: 4rem;
}

.px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-12{
  padding-left: 3rem;
  padding-right: 3rem;
}

.pt-0{
  padding-top: 0px;
}

.pt-0\.5{
  padding-top: 0.125rem;
}

.pb-12{
  padding-bottom: 3rem;
}

.pl-8{
  padding-left: 2rem;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.text-xs{
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-9xl{
  font-size: 8rem;
  line-height: 1;
}

.font-semibold{
  font-weight: 600;
}

.font-bold{
  font-weight: 700;
}

.font-extrabold{
  font-weight: 800;
}

.uppercase{
  text-transform: uppercase;
}

.lowercase{
  text-transform: lowercase;
}

.capitalize{
  text-transform: capitalize;
}

.italic{
  font-style: italic;
}

.underline{
  text-decoration: underline;
}

*, ::before, ::after{
  --tw-shadow: 0 0 #0000;
}

.shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

*, ::before, ::after{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Poppins", monospace;
}

.cms-main-content {
  flex-grow: 1;
  margin-left: 280px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 1366px) {
  .cms-main-content {
    margin-left: 270px !important;
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .cms-main-content {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 720px) {
  .r-title {
    font-size: 28px !important;
    font-weight: 600;
    margin: 0;
  }
}

.r-title-container {
  padding-top: 15%;
}

.r-title {
  font-size: 48px;
  font-weight: 600;
  margin: 20px;
}

.text-center {
  text-align: center !important;
}

.r-subtitle {
  font-size: 20px;
  font-weight: normal;
  margin: 20px;
}

.mobile-display .form-control {
  border: 1px solid #cacaca00 !important;
  cursor: text !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.mobile-display .selected-flag {
  padding: 0px !important;
}

.mobile-display .selected-flag .arrow {
  border-top: 4px solid rgba(85, 85, 85, 0) !important;
}

@media (min-width: 640px){
}

@media (min-width: 768px){
}

@media (min-width: 1024px){
}

@media (min-width: 1280px){
}

@media (min-width: 1536px){
}

/* purgecss start ignore */

.signin_loginBg__1r5uf {
  background: rgb(37, 97, 65);
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/BG.90601798.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.signin_login__3d6Rt {
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  background: white;
  position: relative;
  top: 150px;
  padding: 50px;
  width: 30%;
}

.signin_login__3d6Rt img {
  height: 50px;
  margin-bottom: 20px;
}

.signin_login__3d6Rt button {
  color: white;
  font-weight: bold;
  border-radius: 5px;
  text-transform: none;
}

.signin_login__3d6Rt .signin_signWith__2dJl- {
  color: #9f9f9f;
}

.signin_btnGoogle__2mAaH {
  display: inline;
  border: 1px solid #9f9f9f;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #9f9f9f;
  cursor: pointer;
}

.signin_btnGoogle__2mAaH img {
  height: 20px;
  margin: -3px 10px -3px 0px;
}

.signin_flex__2OWpp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signin_flex__2OWpp .signin_border__2s2bM {
  background: #9f9f9f;
  width: 70%;
  height: 1px;
}

.signin_latestNews__3NpaL {
  background: white;
  height: 140px;
  margin-bottom: 10px;
  display: flex;
  overflow: hidden;
}

.signin_latest-news__2Ur3Z img {
  height: 140px;
}

.signin_latest-news__2Ur3Z .signin_text__32-qJ {
  padding: 20px;
  height: 140px;
  font-size: 12px;
}

.signin__title__2wrn6 {
  color: #0d242c;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.signin__subtitle__3J22K {
  color: #444444;
  font-size: 16px;
  font-weight: 600;
}

.signin_link__1xITP {
  color: #c8932a !important;
  -webkit-text-decoration-line: underline !important;
  text-decoration-line: underline !important;
  cursor: pointer;
}

@media only screen and (max-width: 1920px) {
  .signin_login__3d6Rt {
    top: 120px;
    width: 30%;
  }
}

@media only screen and (max-width: 1366px) {
  .signin_login__3d6Rt {
    top: 60px;
    width: 30%;
  }
}

@media only screen and (max-width: 982px) {
  .signin_login__3d6Rt {
    top: 100px;
    width: 40%;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.userListRoot {
  flex-grow: 1;
  margin-left: 280px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.userListBody {
  padding: 20px;
  position: relative;
}

.userListpaper {
  padding: 30px;
  /* border: 1px solid black; */
}

.userListpaper2 {
  height: 100%;
  width: 100%;
  padding: 60px 45px 40px 0;
  /* border: 1px solid black; */
}

.userListtableWidth {
  width: 100%;
}

.userListtitleText {
  font-size: 29px;
}

.userListdescription {
  font-size: 14px;
}

.userListaddButton {
  width: 160px;
  height: 45px;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  margin-top: 28px !important;
  margin-bottom: 28px !important;
  position: absolute !important;
  right: 20px !important;
  top: 80px !important;
  padding: 10px !important;
}

.userListaddButton:hover {
  background-color: #a67717 !important;
}

.userListlabelUser {
  font-size: 16px;
  margin-top: 69px !important;
}

.userListformControl {
  margin-top: 12px;
  width: 270px;
}

.userListformControl Select {
  height: 25px;
  font-size: 15px;
}

.userListtextField {
  width: 240px;
  margin-bottom: 25px !important;
  float: right;
  text-transform: capitalize !important;
}

.userListpagination {
  color: "#444444";
  font-weight: bold;
}

.userListdialogLabel {
  font-size: 20px;
  font-weight: bold;
}

.userListdialogLabel2 {
  font-size: 24px;
  font-weight: bold;
  color: #256141;
}

.userListdialogLabel3 {
  margin-top: 15px;
  font-size: 14px;
}

.userListdialogLabel4 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.userListtextboxs {
  width: 353px;
  font-size: 16px;
}

.userListdeleteBtn {
  width: 380px;
  height: 50px;
  margin: 0 24px 10px 24px;
  background-color: #ed5555;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
}

.userListdeleteBtn:hover {
  background-color: #b83535;
}

.userListcancelBtn {
  font-size: 14px;
  width: 380px;
  height: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
  margin-left: 24px;
}

@media only screen and (max-width: 1500px) {
  .userListpaper {
    padding: 40px 40px 40px 70px;
  }

  .userListpaper2 {
    padding: 50px 31px 40px 0;
  }

  .userListtitleText {
    font-size: 25px;
  }

  .userListdescription {
    font-size: 11px;
  }

  .userListaddButton {
    width: 140px;
    height: 40px;
  }

  .userListlabelUser {
    font-size: 13px;
  }

  .userListformControl {
    width: 240px;
  }

  .userListformControl Select {
    font-size: 14px;
  }

  .userListtextField {
    width: 230px;
  }
}

@media only screen and (max-width: 1366px) {
  .userListRoot {
    /* margin-left: 240px !important; */
    margin-top: 0 !important;
  }

  .userListpaper {
    padding: 40px 40px 40px 30px;
  }

  .userListpaper2 {
    padding: 50px 31px 40px 0;
  }

  .userListtitleText {
    font-size: 25px;
  }

  .userListdescription {
    font-size: 11px;
  }

  .userListaddButton {
    width: 120px;
    height: 35px;
    font-size: 11px !important;
  }

  .userListlabelUser {
    font-size: 12px;
    margin-top: 55px !important;
  }

  .userListformControl {
    width: 220px;
  }

  .userListformControl Select {
    font-size: 13px;
    height: 15px;
  }

  .userListtextField {
    width: 220px;
  }
}

@media only screen and (max-width: 1060px) {
  .userListRoot {
    margin-left: 0px !important;
    margin-top: 8px !important;
  }

  .userListpaper {
    padding: 40px 40px 40px 40px;
  }

  .userListpaper2 {
    padding: 50px 31px 40px 0;
  }

  .userListtitleText {
    font-size: 20px;
  }

  .userListdescription {
    font-size: 12px;
  }

  .userListaddButton {
    width: 130px;
    height: 35px;
    font-size: 12px;
  }

  .userListlabelUser {
    font-size: 12px;
  }

  .userListformControl {
    width: 230px;
  }

  .userListformControl Select {
    font-size: 13px;
  }

  .userListtextField {
    width: 230px;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.navroot {
  display: flex;
  font-family: "Poppins", sans-serif;
  position: static;
}

.navappBar {
  background-color: white !important;
  width: 100%;
  padding: 5px;
}

.navmenuButton {
  display: none !important;
  color: black !important;
}

.navdrawerPaper {
  width: 260px;
  display: block !important;
  background-color: #256141 !important;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.navdrawerPaper::-webkit-scrollbar {
  width: 0 !important;
}

.navdrawerPaper2 {
  width: 260px;
  display: none !important;
  background-color: #256141 !important;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.navdrawerPaper2::-webkit-scrollbar {
  width: 0 !important;
}

.navavatar {
  height: 50px !important;
  width: 50px !important;
  left: 96%;
}

.navdashboardLogo {
  margin: -45px 0 50px 52px;
  width: 157px;
  height: 79px;
}

.navmenuPadding {
  margin-left: 15px !important;
}

.navmenuColor {
  color: #a7c2b4 !important;
}

.navmenuColorActive {
  color: #ffffff !important;
}

.navmenuFontSize {
  font-size: 14px !important;
}

@media only screen and (max-width: 1366px) {
  .navappBar {
    padding: 0;
  }

  .navavatar {
    height: 40px !important;
    width: 40px !important;
  }

  .navdashboardLogo {
    margin: -45px 0 50px 50px;
    width: 140px;
    height: 67px;
  }

  .navmenuPadding {
    margin-left: 15px !important;
  }

  .navmenuFontSize {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .navmenuButton {
    display: block !important;
  }

  .navdrawerPaper {
    display: none !important;
  }

  .navdrawerPaper2 {
    display: block !important;
  }

  .navnotif {
    left: 87%;
  }

  .navavatar {
    left: 90%;
  }
}

@media only screen and (max-width: 960px) {
  .navnotif {
    left: 83%;
  }

  .navavatar {
    left: 87%;
  }
}

@media only screen and (max-width: 660px) {
  .navnotif {
    left: 71%;
  }

  .navavatar {
    left: 77%;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.categoriesRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.categoriesPaper {
  height: auto;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  padding: 49px 50px 40px 50px;
  /* border: 1px solid black; */
}

.categoriesPaper2 {
  height: 100%;
  width: 100%;
  padding: 60px 45px 40px 0;
  /* border: 1px solid black; */
}

.categoriesTableWidth {
  width: 100%;
}

.categoriesTitleText {
  font-size: 29px;
}

.categoriesDescription {
  font-size: 14px;
}

.categoriesAddButton {
  width: 181px;
  height: 50px;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  margin-top: 28px !important;
}

.categoriesAddButton:hover {
  background-color: #a67717 !important;
}

.categoriesTextField {
  width: 240px;
  margin-bottom: 25px !important;
  float: right;
  text-transform: capitalize !important;
}

.categoriesPagination {
  color: "#444444";
  font-weight: bold;
}

.categoriesIconBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ed5555 !important;
  border-radius: 100% !important;
  height: 65px;
  width: 65px;
}

.categoriesIcon {
  margin-top: 9px;
  color: white !important;
  font-size: 40px !important;
}

.categoriesDialogTitle {
  font-size: 20px !important;
  font-weight: 600;
}

.categoriesDialogName {
  font-size: 24px !important;
  font-weight: 600;
  color: #256141 !important;
}

.categoriesDialogDeleteBtn {
  background-color: #ed5555 !important;
  color: white !important;
  height: 55px;
  margin: 0 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.categoriesDialogDeleteBtn:hover {
  background-color: #bd3b3b !important;
}

.categoriesDialogCancelBtn {
  height: 55px;
  margin: 5px 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.categoriesLoading {
  margin-left: 45% !important;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1500px) {
  .categoriesPaper {
    padding: 40px 40px 40px 40px;
  }

  .categoriesPaper2 {
    padding: 50px 31px 40px 0;
  }

  .categoriesTitleText {
    font-size: 25px;
  }

  .categoriesDescription {
    font-size: 12px;
  }

  .categoriesAddButton {
    width: 150px;
    height: 40px;
    font-size: 12px !important;
  }

  .categoriesTextField {
    width: 220px;
  }

  .categoriesIconBackground {
    height: 55px;
    width: 55px;
  }

  .categoriesIcon {
    margin-top: 8px;
    font-size: 35px !important;
  }

  .categoriesDialogTitle {
    font-size: 17px !important;
  }

  .categoriesDialogName {
    font-size: 21px !important;
  }

  .categoriesDialogDeleteBtn {
    height: 50px;
    font-size: 12px !important;
  }

  .categoriesDialogCancelBtn {
    height: 50px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .categoriesRoot {
    margin-left: 240px !important;
    margin-top: 0 !important;
  }

  .categoriesPaper {
    padding: 40px 40px 40px 40px;
  }

  .categoriesPaper2 {
    padding: 50px 31px 40px 0;
  }

  .categoriesTitleText {
    font-size: 24px;
  }

  .categoriesDescription {
    font-size: 12px;
  }

  .categoriesAddButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .categoriesTextField {
    width: 230px;
  }

  .categoriesIconBackground {
    height: 45px;
    width: 45px;
  }

  .categoriesIcon {
    margin-top: 6px;
    font-size: 30px !important;
  }

  .categoriesDialogTitle {
    font-size: 14px !important;
  }

  .categoriesDialogName {
    font-size: 18px !important;
  }

  .categoriesDialogDeleteBtn {
    height: 45px;
    font-size: 11px !important;
  }

  .categoriesDialogCancelBtn {
    height: 45px;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .categoriesRoot {
    margin-left: 0 !important;
  }

  .categoriesPaper {
    padding: 40px 40px 40px 40px;
  }

  .categoriesPaper2 {
    padding: 50px 31px 40px 0;
  }

  .categoriesTitleText {
    font-size: 24px;
  }

  .categoriesDescription {
    font-size: 12px;
  }

  .categoriesAddButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .categoriesTextField {
    width: 230px;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.addUserRoot {
  flex-grow: 1;
  width: 820px;
  margin-left: 780px !important;
  margin-top: 50px !important;
  display: flex;
  flex-direction: column;
  align-items: "center";
  font-family: "Poppins", sans-serif;
}

.addUserlabels {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.addUserlabels1 {
  font-size: 29px !important;
  font-weight: bold !important;
}

.addUserlabels2 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.addUsertextboxs {
  width: 242px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.addUsertextboxs1 {
  width: 202px !important;
  font-size: 16px !important;
}

.addUsertextboxs1Text {
  font-size: 16px !important;
}

.addUsertextboxs2 {
  width: 522px !important;
  font-size: 16px !important;
}

.addUserselectboxs {
  width: 223px !important;
  font-size: 16px !important;
}

.addUseraddButton {
  right: -85px !important;
  width: 165px !important;
  height: 45px !important;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

.addUseraddButton:hover {
  background-color: #a67717 !important;
}

.MuiCircularProgress-colorPrimary {
  color: "#1AB071" !important;
}

.addUseralertWidth {
  width: 531px !important;
  margin-top: 20px !important;
}

@media only screen and (max-width: 1500px) {
  .addUserRoot {
    width: 630px;
    margin-left: 40% !important;
    margin-top: 40px !important;
  }

  .addUserlabels {
    font-size: 14px !important;
  }

  .addUserlabels1 {
    font-size: 25px !important;
  }

  .addUserlabels2 {
    font-size: 19px !important;
  }

  .addUsertextboxs {
    width: 180px !important;
    font-size: 13px !important;
  }

  .addUsertextboxs1 {
    width: 161px !important;
    font-size: 13px !important;
  }

  .addUsertextboxs1Text {
    font-size: 13px !important;
  }

  .addUsertextboxs2 {
    width: 395px !important;
    font-size: 13px !important;
  }

  .addUserselectboxs {
    width: 163px !important;
    font-size: 13px !important;
  }

  .addUseraddButton {
    right: -55px !important;
    width: 130px !important;
    height: 40px !important;
    font-size: 12px !important;
  }

  .addUseralertWidth {
    width: 405px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .addUserRoot {
    width: 630px !important;
    margin-left: 580px !important;
    margin-top: 40px !important;
  }

  .addUserlabels {
    font-size: 14px !important;
  }

  .addUserlabels1 {
    font-size: 25px !important;
  }

  .addUserlabels2 {
    font-size: 19px !important;
  }

  .addUsertextboxs {
    width: 180px !important;
    font-size: 13px !important;
  }

  .addUsertextboxs1 {
    width: 161px !important;
    font-size: 13px !important;
  }

  .addUsertextboxs1Text {
    font-size: 13px !important;
  }

  .addUsertextboxs2 {
    width: 395px !important;
    font-size: 13px !important;
  }

  .addUserselectboxs {
    width: 163px !important;
    font-size: 13px !important;
  }

  .addUseraddButton {
    right: -70px !important;
    width: 120px !important;
    height: 37px !important;
    font-size: 12px !important;
  }

  .addUseralertWidth {
    width: 405px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .addUserRoot {
    width: 630px !important;
    margin-left: 300px !important;
    margin-top: 40px !important;
  }
}

#parent-form {
  width: 540px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Button_button__2_seP {
  height: 45px;
  text-transform: capitalize !important;
  padding-inline: 1.5rem !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

@media only screen and (min-width: 1280px) {
  .editUser_root__3Jgih {
    flex-grow: 1;
    width: 720px;
    margin-left: 550px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: "center";
    font-family: "Poppins", sans-serif;
  }

  .editUser_labels__1ScFm {
    font-size: 13px;
    font-weight: 600;
  }

  .editUser_labels1__m3FiQ {
    font-size: 22px;
    font-weight: bold;
  }

  .editUser_labels2__3Hezi {
    font-size: 17px;
    font-weight: 600;
  }

  .editUser_textboxs__281aD {
    width: 150px;
    font-size: 13px;
    text-transform: capitalize;
  }

  .editUser_textboxs1__1qLRU {
    width: 115px;
    font-size: 13px;
  }

  .editUser_textboxs1Text__ieBpN {
    font-size: 13px;
  }

  .editUser_textboxs2__3TNHf {
    width: 336px;
    font-size: 13px;
  }

  .editUser_selectboxs__1nFri {
    width: 133px;
    font-size: 13px;
  }

  .editUser_addButton__1FsgZ {
    margin-left: 30px;
    width: 130px;
    height: 40px;
    color: #ffffff;
    background-color: #c8932a;
    text-transform: capitalize;
    font-size: 12px;
  }

  .editUser_addButton__1FsgZ:hover {
    background-color: #a67717;
  }

  .editUser_MuiCircularProgress-colorPrimary__16LoL {
    color: "#1AB071" !important;
  }

  .editUser_alertWidth__2-XED {
    width: 347px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1920px) {
  .editUser_root__3Jgih {
    flex-grow: 1;
    width: 1100px;
    margin-left: 790px;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: "center";
    font-family: "Poppins", sans-serif;
  }

  .editUser_labels__1ScFm {
    font-size: 16px;
    font-weight: 600;
  }

  .editUser_labels1__m3FiQ {
    font-size: 29px;
    font-weight: bold;
  }

  .editUser_labels2__3Hezi {
    font-size: 20px;
    font-weight: 600;
  }

  .editUser_textboxs__281aD {
    width: 242px;
    font-size: 16px;
    text-transform: capitalize;
  }

  .editUser_textboxs1__1qLRU {
    width: 202px;
    font-size: 16px;
  }

  .editUser_textboxs1Text__ieBpN {
    font-size: 16px;
  }

  .editUser_textboxs2__3TNHf {
    width: 522px;
    font-size: 16px;
  }

  .editUser_selectboxs__1nFri {
    width: 223px;
    font-size: 16px;
  }

  .editUser_addButton__1FsgZ {
    margin-left: 70px;
    width: 181px;
    height: 50px;
    color: #ffffff;
    background-color: #c8932a;
    text-transform: capitalize;
    font-size: 14px;
  }

  .editUser_addButton__1FsgZ:hover {
    background-color: #a67717;
  }

  .editUser_MuiCircularProgress-colorPrimary__16LoL {
    color: "#1AB071" !important;
  }

  .editUser_alertWidth__2-XED {
    width: 531px;
    margin-top: 20px;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  margin-left: 240px;
  height: 100%;
}

.containerLeft {
  height: auto;
  min-height: 87vh;
  width: 50%;
  padding: 18px 55px;
  box-shadow: 1px -3px 4px 4px #ccc;
}

.breadcrumbsSize {
  font-size: 12px !important;
  text-transform: capitalize;
}

.clientNameLabel {
  font-size: 29px;
  font-weight: bold;
  text-transform: capitalize;
}

.clientEmailLabel {
  margin-top: 4px;
  font-size: 14px;
}

.detailsTable {
  width: 100%;
  margin-top: 26px;
  font-size: 14px;
  text-align: right;
  padding-bottom: 10px;
}

.detailsTable td:first-child {
  font-weight: bold;
  text-align: left;
}

.tabBottomColor {
  background: #256141 !important;
}

.tabTextActive {
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: capitalize !important;
  font-weight: bold !important;
  color: #256141 !important;
  height: 60px;
}

.tabText {
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: capitalize !important;
  font-weight: bold !important;
  height: 60px;
}

.tabItemsContainerActive {
  padding: 25px 18px;
  border: 2px solid #1ab071 !important;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.tabItemsContainerActive:hover {
  background-color: #c4c4c4a2;
}

.tabItemsContainer {
  padding: 25px 18px;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.tabItemsContainer:hover {
  background-color: #c4c4c4a2;
}

.iconBackground {
  padding-top: 10px;
  margin-right: 19px;
  background: #256141;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconSize {
  font-size: 25px !important;
  color: white;
}

.itemTitle {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.itemDescription {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

/* CONTENT RIGHT */

.containerRight {
  height: 100%;
  width: 100%;
  padding: 47px 80px;
  /* border: 1px solid black; */
}

.content2Title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 60px;
}

.validatedLabel {
  width: 100%;
  background-color: #256141;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 8px 0;
  margin-bottom: 20px;
}

.assignManagerButton {
  margin: 10px 0 20px 0 !important;
  height: 50px;
  width: 100%;
  background: #256141 !important;
  color: white !important;
  border-radius: 5px !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.assignManagerButton:hover {
  background-color: #18442d !important;
}

.assignManagerButton2 {
  margin: 10px 0 20px 0 !important;
  height: 50px;
  width: 100%;
  border: 1px solid #256141 !important;
  background: white !important;
  color: #256141 !important;
  border-radius: 5px !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.assignManagerButton2:hover {
  background-color: rgb(207, 196, 196) !important;
}

.content2IconBackground {
  padding-top: 10px;
  margin-right: 19px;
  background: #256141;
  width: 58px;
  height: 58px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content2IconSize {
  font-size: 38px !important;
  color: white;
}

.content2ItemTitle {
  font-size: 29px;
  font-weight: 600;
}

.content2ItemDescription {
  font-size: 14px;
  font-weight: 400;
}

.accordionTitle {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.accordionTable {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: black;
  border-collapse: collapse;
  text-transform: capitalize;
}

.accordionTable tr {
  padding-left: 20px;
  height: 68px;
}

.accordionTable td {
  border-bottom: 1px solid #dadada !important;
}

.accordionTableTitle {
  color: black !important;
  font-size: 16px !important;
}

.containerRight td:first-child {
  width: 440px;
  padding-right: 30px;
  font-weight: 600;
  color: #a4a4a4;
}

.btnNextPrev {
  height: 41px;
  background-color: #eaeaea !important;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 600 !important;
  width: 100%;
}

.btnNextPrev:hover {
  background-color: #b4adad !important;
}

.btnDecline {
  margin-top: 34px !important;
  height: 50px;
  width: 181px;
  color: #ed5555 !important;
  border: 1px solid #ed5555 !important;
  border-radius: 5px;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.btnDecline:hover {
  background-color: #d8777781 !important;
}

.btnApprove {
  height: 50px;
  width: 181px;
  background: #256141 !important;
  color: white !important;
  border-radius: 5px;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.btnApprove:hover {
  background-color: #18442d !important;
}

.btnApprove:disabled {
  pointer-events: auto;
  background: #a4a4a4 !important;
}

.btnSpanApprove {
  float: right;
  margin-top: 34px !important;
}

.riskContainer {
  padding: 18px;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.riskIconBackgroundCon {
  padding-top: 12px;
  margin-right: 19px;
  background: #507be9;
  width: 44px;
  height: 44px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.riskIconBackgroundMod {
  padding-top: 10px;
  margin-right: 19px;
  background: #c8932a;
  width: 44px;
  height: 44px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.riskIconBackgroundAgg {
  padding-top: 10px;
  margin-right: 19px;
  background: #ed5555;
  width: 44px;
  height: 44px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attachmentLabel {
  font-size: 20px;
  font-weight: 600;
}

.attachmentDiv {
  margin-top: 15px;
  width: 280px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  cursor: pointer;
  word-break: break-all;
}

.attachmentIcon {
  height: 35px;
  width: 30px;
  margin-right: 18px;
  cursor: pointer;
}

.dialogIconBackground {
  margin: 25px 25px 10px 25px;
  background: #256141 !important;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogIconBackgroundDecline {
  margin: 25px 25px 10px 25px;
  background: #ed5555 !important;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogIcon {
  margin-top: 5px;
  font-size: 40px !important;
  color: white !important;
}

.dialogTitle {
  font-size: 24px;
  font-weight: 600;
}

.dialogClientName {
  font-size: 24px;
  font-weight: 600;
  color: #256141 !important;
  text-transform: capitalize;
}

.dialogDescription {
  font-size: 14px;
  font-weight: 400;
}

.dialogApproveButton {
  margin: 0 25px 25px 25px !important;
  background: #256141 !important;
  color: white !important;
  text-transform: capitalize !important;
  height: 50px;
  width: 380px !important;
  font-weight: 600 !important;
}

.dialogApproveButton:hover {
  background-color: #18442d !important;
}

.dialogLegacyButton {
  margin: -15px 25px 20px 25px !important;
  height: 50px;
  color: #256141 !important;
  border: 1px solid #256141 !important;
  text-transform: capitalize !important;
  font-size: 12px;
  font-weight: 600 !important;
}

.dialogCancelButton {
  margin: -15px 25px 20px 25px !important;
  height: 50px;
  color: #ed5555 !important;
  border: 1px solid #ed5555 !important;
  text-transform: capitalize !important;
  font-size: 14px;
  width: 380px !important;
  font-weight: 600 !important;
}

.dialogCancelButton:hover {
  background-color: #d8777781 !important;
}

.dialogDropdown {
  width: 380px;
}

.dialogDropdownSelect {
  font-size: 14px !important;
  height: 50px !important;
}

.dialogLoading {
  margin-left: 45%;
}

.imagePreview {
  width: 40%;
  height: 40%;
  object-fit: scale-down;
}

.transContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
}

.pendingApprovedContainer {
  height: 450px;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.pendingApprovedContainer::-webkit-scrollbar {
  width: 0 !important;
}

.noInvestmentTab {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.noInvestmentContent {
  font-size: 20px;
  font-weight: 600;
}

@media only screen and (max-width: 1500px) {
  .root {
    margin-left: 260px;
  }

  .containerLeft {
    width: 50%;
    padding: 18px 35px;
  }

  .breadcrumbsSize {
    font-size: 11px !important;
  }

  .clientNameLabel {
    font-size: 23px;
  }

  .clientEmailLabel {
    margin-top: 4px;
    font-size: 11px;
  }

  .detailsTable {
    margin-top: 26px;
    font-size: 11px;
  }

  .tabTextActive {
    font-size: 12px !important;
    height: 20px !important;
  }

  .tabText {
    font-size: 12px !important;
    height: 20px !important;
  }

  .tabItemsContainerActive {
    padding: 18px 18px;
  }

  .tabItemsContainer {
    padding: 18px 18px;
  }

  .iconBackground {
    padding-top: 9px;
    margin-right: 14px;
    width: 40px;
    height: 40px;
  }

  .iconSize {
    font-size: 22px !important;
  }

  .itemTitle {
    font-size: 11px;
  }

  .itemDescription {
    font-size: 11px;
  }

  /* CONTENT RIGHT */

  .containerRight {
    padding: 37px 50px;
  }

  .content2Title {
    margin-bottom: 37px;
  }

  .validatedLabel {
    font-size: 14px;
  }

  .assignManagerButton {
    height: 40px;
    font-size: 12px !important;
  }

  .assignManagerButton2 {
    height: 40px;
    font-size: 12px !important;
  }

  .content2IconBackground {
    padding-top: 9px;
    margin-right: 19px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
  }

  .content2IconSize {
    font-size: 32px !important;
    color: white;
  }

  .content2ItemTitle {
    font-size: 20px;
  }

  .content2ItemDescription {
    font-size: 12px;
  }

  .accordionTitle {
    font-size: 14px;
  }

  .accordionTable {
    font-size: 11px;
  }

  .accordionTable tr {
    height: 48px;
  }

  .accordionTableTitle {
    font-size: 11px !important;
  }

  .containerRight td:first-child {
    width: 310px;
  }

  .btnNextPrev {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    height: 35px;
    font-size: 12px !important;
  }

  .btnDecline {
    margin-top: 30px !important;
    height: 40px;
    width: 141px;
    font-size: 12px !important;
  }

  .btnApprove {
    margin-top: 30px !important;
    height: 40px;
    width: 141px;
    font-size: 12px !important;
  }

  .riskContainer {
    padding: 14px !important;
  }

  .riskIconBackgroundCon {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .riskIconBackgroundMod {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .riskIconBackgroundAgg {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .attachmentLabel {
    font-size: 16px;
  }

  .attachmentDiv {
    margin-top: 10px;
    width: 220px;
    padding: 14px;
  }

  .attachmentIcon {
    height: 30px;
    width: 25px;
  }

  .dialogIconBackground {
    margin: 25px 25px 10px 25px;
    height: 50px;
    width: 50px;
  }

  .dialogIconBackgroundDecline {
    margin: 25px 25px 10px 25px;
    height: 50px;
    width: 50px;
  }

  .dialogIcon {
    margin-top: 8px;
    font-size: 30px !important;
  }

  .dialogTitle {
    font-size: 20px;
  }

  .dialogClientName {
    font-size: 20px;
  }

  .dialogDescription {
    font-size: 12px;
  }

  .dialogApproveButton {
    height: 40px;
    width: 340px !important;
    font-size: 12px !important;
  }

  .dialogCancelButton {
    height: 40px;
    font-size: 12px;
    width: 340px !important;
  }

  .dialogDropdown {
    width: 340px;
  }

  .dialogDropdownSelect {
    font-size: 12px !important;
    height: 40px !important;
  }

  .dialogLoading {
    margin-left: 43%;
  }

  .pendingApprovedContainer {
    height: 380px;
  }
}

@media only screen and (max-width: 1366px) {
  .root {
    margin-top: 0;
    margin-left: 240px;
  }

  .containerLeft {
    width: 34%;
    padding: 18px 25px;
  }

  .breadcrumbsSize {
    font-size: 10px !important;
  }

  .clientNameLabel {
    font-size: 22px;
  }

  .clientEmailLabel {
    margin-top: 4px;
    font-size: 10px;
  }

  .detailsTable {
    margin-top: 26px;
    font-size: 10px;
  }

  .tabTextActive {
    font-size: 11px !important;
    height: 20px !important;
  }

  .tabText {
    font-size: 11px !important;
    height: 20px !important;
  }

  .tabItemsContainerActive {
    padding: 18px 18px;
  }

  .tabItemsContainer {
    padding: 18px 18px;
  }

  .iconBackground {
    padding-top: 10px;
    margin-right: 14px;
    width: 40px;
    height: 40px;
  }

  .iconSize {
    font-size: 22px !important;
  }

  .itemTitle {
    font-size: 11px;
  }

  .itemDescription {
    font-size: 11px;
  }

  /* CONTENT RIGHT */

  .containerRight {
    padding: 37px 50px;
  }

  .content2Title {
    margin-bottom: 37px;
  }

  .validatedLabel {
    font-size: 11px;
  }

  .assignManagerButton {
    height: 35px;
    font-size: 11px !important;
  }

  .assignManagerButton2 {
    height: 35px;
    font-size: 11px !important;
  }

  .content2IconBackground {
    padding-top: 9px;
    margin-right: 19px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
  }

  .content2IconSize {
    font-size: 32px !important;
    color: white;
  }

  .content2ItemTitle {
    font-size: 19px;
  }

  .content2ItemDescription {
    font-size: 11px;
  }

  .accordionTitle {
    font-size: 13px;
  }

  .accordionTable {
    font-size: 10px;
  }

  .accordionTable tr {
    height: 45px;
  }

  .accordionTableTitle {
    font-size: 11px !important;
  }

  .containerRight td:first-child {
    width: 310px;
  }

  .btnNextPrev {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    height: 35px;
    font-size: 11px !important;
  }

  .btnDecline {
    margin-top: 30px !important;
    height: 35px;
    width: 141px;
    font-size: 11px !important;
  }

  .btnApprove {
    margin-top: 30px !important;
    height: 35px;
    width: 141px;
    font-size: 11px !important;
  }

  .riskContainer {
    padding: 14px !important;
  }

  .riskIconBackgroundCon {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .riskIconBackgroundMod {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .riskIconBackgroundAgg {
    padding-top: 9px;
    margin-right: 13px;
    width: 40px;
    height: 40px;
  }

  .attachmentLabel {
    font-size: 14px;
  }

  .attachmentDiv {
    width: 200px;
    padding: 12px;
  }

  .attachmentIcon {
    height: 30px;
    width: 25px;
  }

  .dialogIconBackground {
    margin: 25px 25px 10px 25px;
    height: 50px;
    width: 50px;
  }

  .dialogIconBackgroundDecline {
    margin: 25px 25px 10px 25px;
    height: 50px;
    width: 50px;
  }

  .dialogIcon {
    margin-top: 8px;
    font-size: 30px !important;
  }

  .dialogTitle {
    font-size: 20px;
  }

  .dialogClientName {
    font-size: 20px;
  }

  .dialogDescription {
    font-size: 12px;
  }

  .dialogApproveButton {
    height: 40px;
    width: 340px !important;
    font-size: 12px !important;
  }

  .dialogCancelButton {
    height: 40px;
    font-size: 12px;
    width: 340px !important;
  }

  .dialogDropdown {
    width: 340px;
  }

  .dialogDropdownSelect {
    font-size: 12px !important;
    height: 40px !important;
  }

  .dialogLoading {
    margin-left: 43%;
  }
}

@media only screen and (max-width: 1200px) {
  .root {
    margin-top: 5px;
    margin-left: 240px;
  }

  .containerLeft {
    width: 35%;
  }

  .breadcrumbsSize {
    font-size: 10px !important;
  }

  .clientNameLabel {
    font-size: 20px;
  }

  .clientEmailLabel {
    margin-top: 4px;
    font-size: 10px;
  }

  .detailsTable {
    margin-top: 18px;
    font-size: 10px;
  }

  .tabTextActive {
    width: 10% !important;
    font-size: 10px !important;
    height: 17px !important;
  }

  .tabText {
    width: 10% !important;
    font-size: 10px !important;
    height: 17px !important;
  }

  .tabItemsContainerActive {
    padding: 14px 14px;
  }

  .tabItemsContainer {
    padding: 14px 14px;
  }

  .iconBackground {
    padding-top: 9px;
    margin-right: 14px;
    width: 40px;
    height: 40px;
  }

  .iconSize {
    font-size: 22px !important;
  }

  .itemTitle {
    font-size: 10px;
  }

  .itemDescription {
    font-size: 10px;
  }

  /* CONTENT RIGHT */

  .containerRight {
    padding: 27px 30px;
  }

  .content2Title {
    margin-bottom: 30px;
  }

  .content2IconBackground {
    padding-top: 7px;
    margin-right: 15px;
    width: 40px;
    height: 40px;
  }

  .content2IconSize {
    font-size: 27px !important;
    color: white;
  }

  .content2ItemTitle {
    font-size: 17px;
  }

  .validatedLabel {
    font-size: 11px;
  }

  .assignManagerButton {
    height: 35px;
    font-size: 11px !important;
  }

  .assignManagerButton2 {
    height: 35px;
    font-size: 11px !important;
  }

  .content2ItemDescription {
    font-size: 10px;
  }

  .accordionTitle {
    font-size: 12px;
  }

  .accordionTable {
    font-size: 10px;
  }

  .accordionTable tr {
    height: 40px;
  }

  .accordionTableTitle {
    font-size: 10px !important;
  }

  .containerRight td:first-child {
    width: 230px;
  }

  .btnNextPrev {
    margin-bottom: 20px !important;
    height: 30px;
    font-size: 10px !important;
  }

  .btnDecline {
    margin-top: 20px !important;
    height: 30px;
    width: 131px;
    font-size: 10px !important;
  }

  .btnApprove {
    margin-top: 20px !important;
    height: 30px;
    width: 131px;
    font-size: 10px !important;
  }

  .riskContainer {
    padding: 10px !important;
  }

  .riskIconBackgroundCon {
    padding-top: 9px;
    margin-right: 13px;
    width: 35px;
    height: 35px;
  }

  .riskIconBackgroundMod {
    padding-top: 9px;
    margin-right: 13px;
    width: 35px;
    height: 35px;
  }

  .riskIconBackgroundAgg {
    padding-top: 9px;
    margin-right: 13px;
    width: 35px;
    height: 35px;
  }

  .dialogIconBackground {
    margin: 25px 25px 10px 25px;
    height: 45px;
    width: 45px;
  }

  .dialogIconBackgroundDecline {
    margin: 25px 25px 10px 25px;
    height: 45px;
    width: 45px;
  }

  .dialogIcon {
    margin-top: 8px;
    font-size: 25px !important;
  }

  .dialogTitle {
    font-size: 17px;
  }

  .dialogClientName {
    font-size: 17px;
  }

  .dialogDescription {
    font-size: 10px;
  }

  .dialogApproveButton {
    height: 35px;
    width: 300px !important;
    font-size: 10px !important;
  }

  .dialogCancelButton {
    height: 35px;
    font-size: 10px !important;
    width: 300px !important;
  }

  .dialogDropdown {
    width: 300px;
  }

  .dialogDropdownSelect {
    font-size: 10px !important;
    height: 35px !important;
  }

  .pendingApprovedContainer {
    height: 350px;
  }
}

@media only screen and (max-width: 1060px) {
  .root {
    margin-left: 0;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.body-content {
  padding: 30px;
}

.transaction-list {
  padding: 20px;
  position: relative;
}

.tbl-container {
  padding-left: 40px;
}

.tbl-primary-list {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.tbl-primary-list thead {
  background: #256141;
  color: white;
}

.tbl-primary-list thead th {
  padding: 20px;
}

.tbl-primary-list tbody td {
  padding: 20px;
}

.tbl-primary-list tbody tr:nth-child(even) {
  background: white;
}

.tbl-primary-list tbody tr:nth-child(odd) {
  background: rgba(37, 97, 65, 0.1);
}

.csv_link {
  position: absolute !important;
  right: 20px !important;
  top: 80px !important;
  color: white !important;
}

/* .csv_link span a {
    text-decoration: none !important;
    color: white !important;
} */

/* purgecss end ignore */

/* purgecss start ignore */

.approve-button {
  width: 165px;
  height: 45px;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  font-size: 16px;
}

.approve-button:hover {
  background-color: #a67717 !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  margin-left: 240px;
  height: 100%;
}

.detailsTable {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: black;
  border-collapse: collapse;
  text-transform: capitalize;
}

.detailsTable tr {
  padding-left: 20px;
  height: 68px;
}

.detailsTable td {
  border-bottom: 1px solid #dadada !important;
}

.detailsTableTitle {
  color: black !important;
  font-size: 16px !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.accountRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-left: 260px;
  height: 100%;
}

.accountContainerLeft {
  height: auto;
  min-height: 87vh;
  width: 22%;
  padding: 25px 90px;
  box-shadow: 1px -7px 4px 4px #ccc;
}

.accountBackButton {
  font-size: 14px !important;
  font-weight: 400;
  color: #444444 !important;
  margin-bottom: 28px !important;
  cursor: pointer;
  text-transform: capitalize !important;
}

.accountBackButton:hover {
  background-color: white !important;
  color: #c8932a !important;
}

.accountSideIcon {
  margin-right: 21px;
}

.accountSideButtonActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #256141;
  margin-bottom: 28px;
  cursor: pointer;
}

.accountSideButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin-bottom: 28px;
  cursor: pointer;
}

/* CONTENT RIGHT */

.accountContainerRight {
  height: 100%;
  width: 65%;
  padding: 47px 80px;
  /* border: 1px solid black; */
}

.accountItemHeader {
  font-size: 29px;
  font-weight: 600;
}

.accountItemTitle {
  font-size: 20px;
  font-weight: 600;
}

.accountContentTable {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: black;
  border-collapse: collapse;
}

.accountContentTable tr {
  padding-left: 20px;
  height: 68px;
}

.accountContentTable td {
  border-bottom: 1px solid #dadada !important;
}

.accountContentTable td:first-child {
  width: 390px;
  padding-right: 30px;
  font-weight: 600;
  color: #a4a4a4;
}

.accountOverviewEditButton {
  margin-top: 40px !important;
  height: 50px;
  width: 181px;
  border: 1px solid #dadada !important;
  color: #444444;
  font-size: 14px;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.accountEditAccountFieldContainer {
  display: flex;
  flex-direction: column;
}

.accountEditAccountTitle {
  font-size: 16px;
  font-weight: 600px;
}

.accountEditAccountEmailTextbox {
  width: 100% !important;
  font-size: 17px !important;
  font-weight: 400;
}

.accountEditAccountNumTextbox {
  width: 250px;
  font-size: 17px !important;
  font-weight: 400;
}

.accountEditAccountNumText {
  font-size: 17px !important;
  font-weight: 400;
}

.accountEditAccountSaveButton {
  margin-top: 40px !important;
  height: 50px;
  width: 181px;
  background-color: #256141 !important;
  color: white !important;
  font-size: 14px;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.accountEditAccountSaveButton:hover {
  background-color: #18442d !important;
}

@media only screen and (max-width: 1500px) {
  .accountContainerLeft {
    padding: 25px 50px;
  }

  .accountBackButton {
    font-size: 13px !important;
  }

  .accountSideIcon {
    font-size: 24px !important;
  }

  .accountSideButtonActive {
    font-size: 13px;
  }

  .accountSideButton {
    font-size: 13px;
  }

  /* CONTENT RIGHT */

  .accountContainerRight {
    width: 65%;
    padding: 47px 80px;
  }

  .accountItemHeader {
    font-size: 23px;
  }

  .accountItemTitle {
    font-size: 16px;
  }

  .accountContentTable {
    font-size: 12px;
  }

  .accountContentTable tr {
    padding-left: 20px;
    height: 55px;
  }

  .accountContentTable td:first-child {
    width: 310px;
  }

  .accountOverviewEditButton {
    height: 40px;
    width: 151px;
    font-size: 12px !important;
  }

  .accountEditAccountTitle {
    font-size: 13px;
  }

  .accountEditAccountEmailTextbox {
    height: 10px !important;
    font-size: 14px !important;
  }

  .accountEditAccountNumTextbox {
    height: 40px !important;
    font-size: 14px !important;
  }

  .accountEditAccountNumText {
    font-size: 14px !important;
  }

  .accountEditAccountSaveButton {
    height: 40px;
    width: 141px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .accountRoot {
    margin-top: 0;
    margin-left: 240px;
  }

  .accountContainerLeft {
    width: 24%;
    padding: 25px 45px;
  }

  .accountBackButton {
    font-size: 13px !important;
  }

  .accountSideIcon {
    font-size: 24px !important;
  }

  .accountSideButtonActive {
    font-size: 13px;
  }

  .accountSideButton {
    font-size: 13px;
  }

  /* CONTENT RIGHT */

  .accountContainerRight {
    width: 60%;
    padding: 47px 80px;
  }

  .accountItemHeader {
    font-size: 19px;
  }

  .accountItemTitle {
    font-size: 13px;
  }

  .accountContentTable {
    font-size: 11px;
  }

  .accountContentTable td:first-child {
    width: 250px;
  }

  .accountOverviewEditButton {
    height: 40px;
    width: 111px;
    font-size: 11px !important;
  }

  .accountEditAccountTitle {
    font-size: 12px;
  }

  .accountEditAccountEmailTextbox {
    height: 5px !important;
    font-size: 12px !important;
  }

  .accountEditAccountNumTextbox {
    width: 200px;
    height: 35px !important;
    font-size: 12px !important;
  }

  .accountEditAccountNumText {
    font-size: 12px !important;
  }

  .accountEditAccountSaveButton {
    height: 40px;
    width: 111px;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .accountContainerLeft {
    width: 25%;
    padding: 25px 40px;
  }

  .accountBackButton {
    font-size: 10px !important;
  }

  .accountSideIcon {
    font-size: 20px !important;
  }

  .accountSideButtonActive {
    font-size: 10px;
  }

  .accountSideButton {
    font-size: 10px;
  }

  /* CONTENT RIGHT */

  .accountContainerRight {
    width: 65%;
    padding: 47px 80px;
  }

  .accountItemHeader {
    font-size: 19px;
  }

  .accountItemTitle {
    font-size: 12px;
  }

  .accountContentTable {
    font-size: 10px;
  }

  .accountContentTable td:first-child {
    width: 240px;
  }

  .accountOverviewEditButton {
    height: 35px;
    width: 111px;
    font-size: 10px !important;
  }

  .accountEditAccountTitle {
    font-size: 11px;
  }

  .accountEditAccountEmailTextbox {
    height: 3px !important;
    font-size: 11px !important;
  }

  .accountEditAccountNumTextbox {
    width: 170px !important;
    height: 33px !important;
    font-size: 11px !important;
  }

  .accountEditAccountNumText {
    font-size: 11px !important;
  }

  .accountEditAccountSaveButton {
    height: 35px;
    width: 111px;
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .accountRoot {
    margin-top: 0;
    margin-left: 0;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.termsAndConditionRoot {
  flex-grow: 1;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.termsAndConditionContainer {
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 45%;
}

.termsAndConditionHeader {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.termsAndConditionTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.termsAndConditionTextBox {
  padding: 15px !important;
  font-size: 16px !important;
}

.termsAndConditionDescription {
  font-size: 16px !important;
}

.termsAndConditionCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.termsAndConditionAddButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.termsAndConditionAddButton:hover {
  background-color: #18422b !important;
}

.termsAndConditionLoading {
  float: right !important;
  margin-right: 50px;
}

.termsAndConditionToolbar {
  border: none !important;
}

.termsAndConditionWrapper {
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
}

.termsAndConditionWrapper:hover {
  border: 1px solid black !important;
  border-radius: 5px !important;
}

.termsAndConditionWrapper:focus-within {
  border: 2px solid #c8932a !important;
}

.termsAndConditionWrapperError {
  border: 1px solid red !important;
  border-radius: 5px !important;
}

.termsAndConditionWrapperError:focus-within {
  border: 2px solid red !important;
  border-radius: 5px !important;
}

.termsAndConditionEditor {
  /* border: 1px solid lightgray !important;
    border-radius: 5px !important; */
  min-height: 250px !important;
  padding: 0 10px !important;
  cursor: text !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif;
}

/* .termsAndConditionEditor:hover {
    border: 1px solid black !important;
    border-radius: 5px !important;
}

.termsAndConditionEditor:focus-within {
    border: 2px solid #C8932A !important;
} */

@media only screen and (max-width: 1500px) {
  .termsAndConditionContainer {
    margin-left: 22%;
    width: 51%;
  }

  .termsAndConditionHeader {
    font-size: 24px;
  }

  .termsAndConditionTitle {
    font-size: 14px;
  }

  .termsAndConditionTextBox {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .termsAndConditionDescription {
    font-size: 14px !important;
  }

  .termsAndConditionCancelButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }

  .termsAndConditionAddButton {
    height: 40px;
    width: 120px;
    font-size: 13px !important;
  }

  .termsAndConditionEditor {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .termsAndConditionRoot {
    margin-left: 260px !important;
    margin-top: -20px !important;
  }

  .termsAndConditionContainer {
    margin-left: 21%;
    width: 55%;
    /* border: 1px solid black; */
  }

  .termsAndConditionHeader {
    font-size: 22px;
  }

  .termsAndConditionTitle {
    font-size: 12px;
  }

  .termsAndConditionTextBox {
    padding: 12px !important;
    font-size: 12px !important;
  }

  .termsAndConditionDescription {
    font-size: 12px !important;
  }

  .termsAndConditionCancelButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .termsAndConditionAddButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .termsAndConditionEditor {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .termsAndConditionRoot {
    margin-left: 0 !important;
    margin-top: -20px !important;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.categoriesAddRoot {
  flex-grow: 1;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.categoriesAddContainer {
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 45%;
  /* border: 1px solid black; */
}

.categoriesAddHeader {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.categoriesAddTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.categoriesAddTextBox {
  padding: 20px !important;
  font-size: 16px !important;
}

.categoriesAddDescription {
  font-size: 16px !important;
}

.categoriesAddCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.categoriesAddAddButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.categoriesAddAddButton:hover {
  background-color: #18422b !important;
}

.categoriesAddLoading {
  float: right !important;
  margin-right: 50px;
}

@media only screen and (max-width: 1500px) {
  .categoriesAddContainer {
    margin-left: 22%;
    width: 51%;
  }

  .categoriesAddHeader {
    font-size: 24px;
  }

  .categoriesAddTitle {
    font-size: 14px;
  }

  .categoriesAddTextBox {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .categoriesAddDescription {
    font-size: 14px !important;
  }

  .categoriesAddCancelButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }

  .categoriesAddAddButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .categoriesAddRoot {
    margin-left: 260px !important;
    margin-top: -20px !important;
  }

  .categoriesAddContainer {
    margin-left: 21%;
    width: 55%;
    /* border: 1px solid black; */
  }

  .categoriesAddHeader {
    font-size: 22px;
  }

  .categoriesAddTitle {
    font-size: 12px;
  }

  .categoriesAddTextBox {
    padding: 12px !important;
    font-size: 12px !important;
  }

  .categoriesAddDescription {
    font-size: 12px !important;
  }

  .categoriesAddCancelButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .categoriesAddAddButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .categoriesAddRoot {
    margin-left: 0 !important;
    margin-top: -20px !important;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.categoriesEditRoot {
  flex-grow: 1;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.categoriesEditContainer {
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 45%;
  /* border: 1px solid black; */
}

.categoriesEditHeader {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.categoriesEditTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.categoriesEditTextBox {
  padding: 20px !important;
  font-size: 16px !important;
}

.categoriesEditDescription {
  font-size: 16px !important;
}

.categoriesEditCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.categoriesEditEditButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.categoriesEditEditButton:hover {
  background-color: #18422b !important;
}

.categoriesEditLoading {
  float: right !important;
  margin-right: 50px;
}

@media only screen and (max-width: 1500px) {
  .categoriesEditContainer {
    margin-left: 22%;
    width: 51%;
  }

  .categoriesEditHeader {
    font-size: 24px;
  }

  .categoriesEditTitle {
    font-size: 14px;
  }

  .categoriesEditTextBox {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .categoriesEditDescription {
    font-size: 14px !important;
  }

  .categoriesEditCancelButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }

  .categoriesEditEditButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .categoriesEditRoot {
    margin-left: 260px !important;
    margin-top: -20px !important;
  }

  .categoriesEditContainer {
    margin-left: 21%;
    width: 55%;
    /* border: 1px solid black; */
  }

  .categoriesEditHeader {
    font-size: 22px;
  }

  .categoriesEditTitle {
    font-size: 12px;
  }

  .categoriesEditTextBox {
    padding: 12px !important;
    font-size: 12px !important;
  }

  .categoriesEditDescription {
    font-size: 12px !important;
  }

  .categoriesEditCancelButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .categoriesEditEditButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .categoriesEditRoot {
    margin-left: 0 !important;
    margin-top: -20px !important;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.fundListRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.fundListPaper {
  height: auto;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  padding: 49px 50px 40px 50px;
  /* border: 1px solid black; */
}

.fundListPaper2 {
  height: 100%;
  width: 100%;
  padding: 60px 45px 40px 0;
  /* border: 1px solid black; */
}

.fundListTableWidth {
  width: 100%;
}

.fundListTitleText {
  font-size: 29px;
}

.fundListDescription {
  font-size: 14px;
}

.fundListAddButton {
  width: 181px;
  height: 50px;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  margin-top: 28px !important;
}

.fundListAddButton:hover {
  background-color: #a67717 !important;
}

.fundListAttachButton {
  width: 181px;
  height: 50px;
  color: #c8932a !important;
  background-color: white !important;
  border: 1px solid #c8932a !important;
  text-transform: capitalize !important;
  margin-top: 18px !important;
}

.fundListAttachButton:hover {
  background-color: rgba(209, 209, 209, 0.726) !important;
}

.fundListTextField {
  width: 240px;
  margin-bottom: 25px !important;
  float: right;
  text-transform: capitalize !important;
}

.fundListPagination {
  color: "#444444";
  font-weight: bold;
}

.fundListIconBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ed5555 !important;
  border-radius: 100% !important;
  height: 65px;
  width: 65px;
}

.fundListIcon {
  margin-top: 9px;
  color: white !important;
  font-size: 40px !important;
}

.fundListDialogTitle {
  font-size: 20px !important;
  font-weight: 600;
}

.fundListDialogName {
  font-size: 24px !important;
  font-weight: 600;
  color: #256141 !important;
}

.fundListDialogDeleteBtn {
  background-color: #ed5555 !important;
  color: white !important;
  height: 55px;
  margin: 0 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundListDialogDeleteBtn:hover {
  background-color: #bd3b3b !important;
}

.fundListDialogCancelBtn {
  height: 55px;
  margin: 5px 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundListLoading {
  margin-left: 45% !important;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1500px) {
  .fundListPaper {
    padding: 40px 40px 40px 40px;
  }

  .fundListPaper2 {
    padding: 50px 31px 40px 0;
  }

  .fundListTitleText {
    font-size: 25px;
  }

  .fundListDescription {
    font-size: 12px;
  }

  .fundListAddButton {
    width: 150px;
    height: 40px;
    font-size: 12px !important;
  }

  .fundListAttachButton {
    width: 150px;
    height: 40px;
    font-size: 12px !important;
  }

  .fundListTextField {
    width: 220px;
  }

  .fundListIconBackground {
    height: 55px;
    width: 55px;
  }

  .fundListIcon {
    margin-top: 8px;
    font-size: 35px !important;
  }

  .fundListDialogTitle {
    font-size: 17px !important;
  }

  .fundListDialogName {
    font-size: 21px !important;
  }

  .fundListDialogDeleteBtn {
    height: 50px;
    font-size: 12px !important;
  }

  .fundListDialogCancelBtn {
    height: 50px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .fundListRoot {
    margin-left: 240px !important;
    margin-top: 0 !important;
  }

  .fundListPaper {
    padding: 40px 40px 40px 40px;
  }

  .fundListPaper2 {
    padding: 50px 31px 40px 0;
  }

  .fundListTitleText {
    font-size: 24px;
  }

  .fundListDescription {
    font-size: 12px;
  }

  .fundListAddButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .fundListAttachButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .fundListTextField {
    width: 230px;
  }

  .fundListIconBackground {
    height: 45px;
    width: 45px;
  }

  .fundListIcon {
    margin-top: 6px;
    font-size: 30px !important;
  }

  .fundListDialogTitle {
    font-size: 14px !important;
  }

  .fundListDialogName {
    font-size: 18px !important;
  }

  .fundListDialogDeleteBtn {
    height: 45px;
    font-size: 11px !important;
  }

  .fundListDialogCancelBtn {
    height: 45px;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .fundListRoot {
    margin-left: 0 !important;
  }

  .fundListPaper {
    padding: 40px 40px 40px 40px;
  }

  .fundListPaper2 {
    padding: 50px 31px 40px 0;
  }

  .fundListTitleText {
    font-size: 24px;
  }

  .fundListDescription {
    font-size: 12px;
  }

  .fundListAddButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .fundListAttachButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .fundListTextField {
    width: 230px;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.fundEditRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.fundEditContainerLeft {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 85vh;
  width: 55%;
  padding: 60px 70px 30px 90px;
  /* border: 1px solid black; */
}

.fundEditContainerRight {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: 45%;
  padding: 127px 90px 90px 0;
  /* overflow-y: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none; */
  /* border: 1px solid black; */
}

/* .fundEditContainerRight::-webkit-scrollbar {
    width: 0 !important;
} */

.fundEditHeader {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 30px;
}

.fundEditHeader2 {
  font-size: 21px;
  font-weight: 600;
}

.fundEditTitle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.fundEditTextBox {
  padding: 20px !important;
  font-size: 14px !important;
  height: 10px !important;
}

.fundEditFormControl Select {
  height: 28px;
  font-size: 14px;
}

.fundEditDescription {
  font-size: 14px !important;
}

.fundEditRadioButtonActive {
  border: 2px solid #256141;
  border-radius: 5px;
  padding-right: 35px;
  margin-left: 1px !important;
}

.fundEditRadioButton {
  border: 2px solid transparent;
  border-radius: 5px;
  padding-right: 35px;
  margin-left: 1px !important;
}

.fundEditCancelButton {
  margin-top: 10px !important;
  margin-left: 90px !important;
  background-color: transparent !important;
  height: 50px;
  width: 150px;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundEditAddButton {
  float: right;
  margin-top: 10px !important;
  margin-right: 90px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 50px;
  width: 150px;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundEditAddButton:hover {
  background-color: #1b4930 !important;
}

.fundEditLoading {
  float: right !important;
  margin-top: 10px;
  margin-right: 140px;
}

@media only screen and (max-width: 1500px) {
  .fundEditContainerLeft {
    width: 53%;
    padding: 50px 50px 20px 70px;
  }

  .fundEditContainerRight {
    /* height: 859px; */
    width: 48%;
    padding: 112px 70px 20px 0;
  }

  .fundEditHeader {
    font-size: 23px;
  }

  .fundEditHeader2 {
    font-size: 17px;
  }

  .fundEditTitle {
    font-size: 12px;
  }

  .fundEditTextBox {
    padding: 17px !important;
    font-size: 12px !important;
  }

  .fundEditFormControl Select {
    height: 23px;
    font-size: 12px;
  }

  .fundEditDescription {
    font-size: 12px !important;
  }

  .fundEditRadioButtonText {
    font-size: 14px !important;
  }

  .fundEditCancelButton {
    margin-left: 70px !important;
    height: 45px;
    width: 130px;
    font-size: 12px !important;
  }

  .fundEditAddButton {
    margin-right: 70px !important;
    height: 45px;
    width: 130px;
    font-size: 12px !important;
  }

  .fundEditLoading {
    margin-right: 130px;
  }
}

@media only screen and (max-width: 1366px) {
  .fundEditRoot {
    margin-left: 240px !important;
    margin-top: 0 !important;
  }

  .fundEditContainerLeft {
    width: 53%;
    padding: 50px 50px 20px 50px;
  }

  .fundEditContainerRight {
    /* height: 833px; */
    width: 48%;
    padding: 107px 50px 20px 0;
  }

  .fundEditHeader {
    font-size: 20px;
  }

  .fundEditHeader2 {
    font-size: 16px;
  }

  .fundEditTitle {
    font-size: 11px;
  }

  .fundEditTextBox {
    padding: 14px !important;
    font-size: 11px !important;
  }

  .fundEditFormControl Select {
    height: 16px;
    font-size: 11px;
  }

  .fundEditDescription {
    font-size: 11px !important;
  }

  .fundEditRadioButtonText {
    font-size: 13px !important;
  }

  .fundEditCancelButton {
    margin-left: 50px !important;
    height: 40px;
    width: 110px;
    font-size: 11px !important;
  }

  .fundEditAddButton {
    margin-right: 50px !important;
    height: 40px;
    width: 110px;
    font-size: 11px !important;
  }

  .fundEditLoading {
    margin-right: 80px;
  }
}

@media only screen and (max-width: 1060px) {
  .fundEditRoot {
    margin-left: 0 !important;
  }
}

.upload-box {
  margin-top: 20px;
  border: 1px dashed #9f9f9f;
  box-sizing: border-box;
  border-radius: 5px;
  height: 86px;
  position: relative;
  width: 70%;
  text-align: center;
  width: 100%;
}

.upload-file {
  display: inline-block;
  position: absolute;
  z-index: 999;
  right: 0%;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-box span {
  position: relative;
  top: 30px;
}

.custom-list-upload {
  padding: 20px 0px;
  box-shadow: none !important;
  border-bottom: 1px solid #dadada;
}

.custom-chip-upload {
  background: none !important;
  font-weight: bold;
  color: #256141 !important;
}

.label-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 10px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.fundRatesUploadRoot {
  flex-grow: 1;
  margin-top: 10px;
  margin-left: 260px;
}

.fundRatesUploadContainer {
  display: flex;
  min-height: 85vh;
  flex-direction: column;
  padding: 30px 300px;
  /* border: 1px solid black; */
}

.fundRatesUploadTitle {
  font-size: 29px;
  font-weight: 600;
}

.fundRatesUploadNotice {
  font-size: 17px;
}

.fundRatesUploadNotice2 {
  margin-top: 30px;
  font-size: 19px;
  font-weight: 600;
}

.fundRatesUploadLabels {
  font-size: 13px;
  color: #9f9f9f;
}

.fundRatesUploadIconSize {
  font-size: 20px;
}

.fundRatesUploadChip {
  margin-top: 20px;
  background-color: #256141 !important;
  font-size: 14px;
}

.fundRatesUploadCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundRatesUploadAddButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.fundRatesUploadAddButton:hover {
  background-color: #18422b !important;
}

.fundRatesUploadIconBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ed5555 !important;
  border-radius: 100% !important;
  height: 65px;
  width: 65px;
}

.fundRatesUploadIcon {
  margin-top: 9px;
  color: white !important;
  font-size: 40px !important;
}

.fundRatesUploadDialogTitle {
  font-size: 20px !important;
  font-weight: 600;
}

.fundRatesUploadDialogName {
  font-size: 24px !important;
  font-weight: 600;
  color: #256141 !important;
}

.fundRatesUploadDialogDeleteBtn {
  background-color: #ed5555 !important;
  color: white !important;
  height: 55px;
  margin: 0 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundRatesUploadDialogDeleteBtn:hover {
  background-color: #bd3b3b !important;
}

.fundRatesUploadLoading {
  float: right !important;
  margin-right: 40px;
}

.fundRatesUploadDatePickerInput {
  width: 250px !important;
}

.fundRatesUploadDatePicker {
  padding: 20px !important;
  font-size: 14px !important;
  height: 10px !important;
}

@media only screen and (max-width: 1500px) {
  .fundRatesUploadContainer {
    padding: 50px 200px;
  }

  .fundRatesUploadTitle {
    font-size: 22px;
  }

  .fundRatesUploadNotice {
    font-size: 15px;
  }

  .fundRatesUploadNotice2 {
    font-size: 17px;
  }

  .fundRatesUploadLabels {
    font-size: 11px;
  }

  .fundRatesUploadChip {
    font-size: 12px;
  }

  .fundRatesUploadCancelButton {
    height: 40px;
    width: 110px;
    font-size: 12px !important;
  }

  .fundRatesUploadAddButton {
    height: 40px;
    width: 110px;
    font-size: 12px !important;
  }

  .fundRatesUploadIconBackground {
    height: 55px;
    width: 55px;
  }

  .fundRatesUploadIcon {
    margin-top: 8px;
    font-size: 35px !important;
  }

  .fundRatesUploadDialogTitle {
    font-size: 17px !important;
  }

  .fundRatesUploadDialogName {
    font-size: 21px !important;
  }

  .fundRatesUploadDialogDeleteBtn {
    height: 50px;
    font-size: 12px !important;
  }

  .fundRatesUploadDatePicker {
    padding: 17px !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .fundRatesUploadRoot {
    margin-top: 0;
    margin-left: 240px;
  }

  .fundRatesUploadContainer {
    padding: 50px 200px;
  }

  .fundRatesUploadTitle {
    font-size: 20px;
  }

  .fundRatesUploadNotice {
    font-size: 13px;
  }

  .fundRatesUploadNotice2 {
    font-size: 15px;
  }

  .fundRatesUploadLabels {
    font-size: 11px;
  }

  .fundRatesUploadIconSize {
    font-size: 18px;
  }

  .fundRatesUploadChip {
    font-size: 10px;
  }

  .fundRatesUploadCancelButton {
    height: 35px;
    width: 110px;
    font-size: 11px !important;
  }

  .fundRatesUploadAddButton {
    height: 35px;
    width: 110px;
    font-size: 11px !important;
  }

  .fundRatesUploadIconBackground {
    height: 55px;
    width: 55px;
  }

  .fundRatesUploadIcon {
    margin-top: 8px;
    font-size: 35px !important;
  }

  .fundRatesUploadDialogTitle {
    font-size: 17px !important;
  }

  .fundRatesUploadDialogName {
    font-size: 21px !important;
  }

  .fundRatesUploadDialogDeleteBtn {
    height: 50px;
    font-size: 12px !important;
  }

  .fundRatesUploadDatePicker {
    padding: 14px !important;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .fundRatesUploadRoot {
    margin-left: 0;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.companyRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.companyPaper {
  height: auto;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  padding: 49px 50px 40px 50px;
  /* border: 1px solid black; */
}

.companyPaper2 {
  height: 100%;
  width: 100%;
  padding: 60px 45px 40px 0;
  /* border: 1px solid black; */
}

.companyTableWidth {
  width: 100%;
}

.companyTitleText {
  font-size: 29px;
}

.companyDescription {
  font-size: 14px;
}

.companyAddButton {
  width: 181px;
  height: 50px;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  margin-top: 28px !important;
}

.companyAddButton:hover {
  background-color: #a67717 !important;
}

.companyTextField {
  width: 240px;
  margin-bottom: 25px !important;
  float: right;
  text-transform: capitalize !important;
}

.companyPagination {
  color: "#444444";
  font-weight: bold;
}

.companyIconBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ed5555 !important;
  border-radius: 100% !important;
  height: 65px;
  width: 65px;
}

.companyIcon {
  margin-top: 9px;
  color: white !important;
  font-size: 40px !important;
}

.companyDialogTitle {
  font-size: 20px !important;
  font-weight: 600;
}

.companyDialogName {
  font-size: 24px !important;
  font-weight: 600;
  color: #256141 !important;
}

.companyDialogDeleteBtn {
  background-color: #ed5555 !important;
  color: white !important;
  height: 55px;
  margin: 0 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.companyDialogDeleteBtn:hover {
  background-color: #bd3b3b !important;
}

.companyDialogCancelBtn {
  height: 55px;
  margin: 5px 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.companyLoading {
  margin-left: 45% !important;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1500px) {
  .companyPaper {
    padding: 40px 40px 40px 40px;
  }

  .companyPaper2 {
    padding: 50px 31px 40px 0;
  }

  .companyTitleText {
    font-size: 25px;
  }

  .companyDescription {
    font-size: 12px;
  }

  .companyAddButton {
    width: 150px;
    height: 40px;
    font-size: 12px !important;
  }

  .companyTextField {
    width: 220px;
  }

  .companyIconBackground {
    height: 55px;
    width: 55px;
  }

  .companyIcon {
    margin-top: 8px;
    font-size: 35px !important;
  }

  .companyDialogTitle {
    font-size: 17px !important;
  }

  .companyDialogName {
    font-size: 21px !important;
  }

  .companyDialogDeleteBtn {
    height: 50px;
    font-size: 12px !important;
  }

  .companyDialogCancelBtn {
    height: 50px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .companyRoot {
    margin-left: 240px !important;
    margin-top: 0 !important;
  }

  .companyPaper {
    padding: 40px 40px 40px 40px;
  }

  .companyPaper2 {
    padding: 50px 31px 40px 0;
  }

  .companyTitleText {
    font-size: 24px;
  }

  .companyDescription {
    font-size: 12px;
  }

  .companyAddButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .companyTextField {
    width: 230px;
  }

  .companyIconBackground {
    height: 45px;
    width: 45px;
  }

  .companyIcon {
    margin-top: 6px;
    font-size: 30px !important;
  }

  .companyDialogTitle {
    font-size: 14px !important;
  }

  .companyDialogName {
    font-size: 18px !important;
  }

  .companyDialogDeleteBtn {
    height: 45px;
    font-size: 11px !important;
  }

  .companyDialogCancelBtn {
    height: 45px;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .companyRoot {
    margin-left: 0 !important;
  }

  .companyPaper {
    padding: 40px 40px 40px 40px;
  }

  .companyPaper2 {
    padding: 50px 31px 40px 0;
  }

  .companyTitleText {
    font-size: 24px;
  }

  .companyDescription {
    font-size: 12px;
  }

  .companyAddButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .companyTextField {
    width: 230px;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.companyEditRoot {
  flex-grow: 1;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.companyEditContainer {
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 45%;
}

.companyEditHeader {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.companyEditTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.companyEditUpload {
  padding: 15px;
  font-size: 16px !important;
  border-radius: 4px !important;
  border: 1px solid lightgrey;
}

.companyEditUpload:hover {
  border-radius: 4px !important;
  border: 1px solid black;
}

.companyEditUpload:focus {
  outline: none !important;
  border: 2px solid #c8932a;
}

.companyEditTextBox {
  padding: 20px !important;
  font-size: 16px !important;
}

.companyEditDescription {
  font-size: 16px !important;
}

.companyEditCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.companyEditEditButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.companyEditEditButton:hover {
  background-color: #18422b !important;
}

.companyEditLoading {
  float: right !important;
  margin-right: 50px;
}

.companyEditUploadIconSize {
  font-size: 20px !important;
}

.companyEditUploadChip {
  margin-top: 10px;
  background-color: #256141 !important;
  font-size: 14px !important;
}

@media only screen and (max-width: 1500px) {
  .companyEditContainer {
    margin-left: 22%;
    width: 51%;
  }

  .companyEditHeader {
    font-size: 24px;
  }

  .companyEditTitle {
    font-size: 14px;
  }

  .companyEditUpload {
    padding: 10px;
    font-size: 14px !important;
  }

  .companyEditTextBox {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .companyEditDescription {
    font-size: 14px !important;
  }

  .companyEditCancelButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }

  .companyEditEditButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .companyEditRoot {
    margin-left: 260px !important;
    margin-top: -20px !important;
  }

  .companyEditContainer {
    margin-left: 21%;
    width: 55%;
  }

  .companyEditHeader {
    font-size: 22px;
  }

  .companyEditTitle {
    font-size: 12px;
  }

  .companyEditUpload {
    padding: 9px;
    font-size: 12px !important;
  }

  .companyEditTextBox {
    padding: 12px !important;
    font-size: 12px !important;
  }

  .companyEditDescription {
    font-size: 12px !important;
  }

  .companyEditCancelButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .companyEditEditButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .companyEditUploadIconSize {
    font-size: 17px !important;
  }

  .companyEditUploadChip {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .companyEditRoot {
    margin-left: 0 !important;
    margin-top: -20px !important;
  }
}

/* purgecss end ignore */

/*       purgecss start ignore       */

.dashboardRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 240px !important;
  font-family: "Poppins", sans-serif;
}

.dashboardMasterContainer {
  margin: 50px 70px !important;
}

.dashboardLabel {
  font-size: 27px !important;
  font-weight: 600;
}

.dashboardContainerA {
  display: flex;
  flex-direction: row;
  margin-top: 30px !important;
  height: 100%;
}

/*       CONTAINER A1       */

.dashboardContainerA1 {
  border: 1px solid #c4c4c4;
  border-radius: 5px !important;
  overflow: hidden !important;
}

.dashboardContainerA1Label {
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  color: white !important;
  background-color: #256141 !important;
  padding: 15px 25px !important;
  font-size: 16px !important;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 1px;
}

.dashboardContainerA1Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 15px;
}

.dashboardContainerA1Inside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboardContainerA1Inside:hover {
  background-color: #e4e3e3 !important;
}

.dashboardContainerA1Inside:not(:nth-child(4n + 1)) {
  margin-left: 20px !important;
}

.dashboardContainerA1InsideLabel {
  font-size: 20px !important;
  font-weight: 600 !important;
  cursor: pointer;
}

.dashboardContainerA1InsideLabel2 {
  font-size: 40px !important;
  font-weight: 500 !important;
  color: #dd1616 !important;
  cursor: pointer;
}

.dashboardContainerA1InsideLabel3 {
  font-size: 40px !important;
  font-weight: 500 !important;
  color: #a4a4a4 !important;
  cursor: pointer;
}

.dashboardContainerA1InsideButton {
  width: 100% !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  text-transform: capitalize !important;
}

/*       CONTAINER A2       */

.dashboardContainerA2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 20px;
}

.dashboardContainerA2Content1 {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  height: 100%;
}

.dashboardContainerA2Content1Label {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.dashboardContainerA2Content1Label2 {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #256141 !important;
  margin-top: 20px !important;
}

.dashboardContainerA2Content1Img {
  height: 90px !important;
  width: 190px !important;
}

.dashboardContainerA2Content1Divider {
  margin: 0 40px;
  border-left: 1px solid #c4c4c4;
  height: 90px;
}

.dashboardContainerA2Content1Count {
  font-size: 40px !important;
  font-weight: 500 !important;
}

.dashboardContainerA2Content1Acc {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.dashboardContainerA2Content2 {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
}

/*       CONTAINER B       */

.dashboardContainerB {
  display: flex;
  flex-direction: row;
  margin-top: 20px !important;
  height: 100%;
  width: 75em;
}

.dashboardContainerB1 {
  margin-right: 20px;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
}

.dashboardContainerB1FormControl {
  margin-left: 220px !important;
  width: 150px !important;
}

.dashboardContainerB1FormControl Select {
  height: 20px !important;
  font-size: 15px;
}

.dashboardContainerB1ContentCount {
  margin-top: 60px !important;
  margin-bottom: 30px !important;
  font-size: 40px !important;
  font-weight: 500 !important;
}

.dashboardContainerB2 {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
}

.dashboardContainerB2FormControl {
  margin-left: 70px !important;
  width: 150px !important;
}

.dashboardContainerB2FormControl Select {
  height: 20px !important;
  font-size: 15px;
}

.dashboardContainerB2ContentCount {
  margin-top: 60px !important;
  margin-bottom: 30px !important;
  font-size: 40px !important;
  font-weight: 500 !important;
  color: #256141 !important;
}

@media only screen and (max-width: 1500px) {
  .dashboardLabel {
    font-size: 21px !important;
  }

  .dashboardContainerA {
    margin-top: 20px !important;
  }

  /*       CONTAINER A1       */

  .dashboardContainerA1 {
    width: 75em;
  }

  .dashboardContainerA1Label {
    font-size: 14px !important;
  }

  .dashboardContainerA1Inside {
    width: 210px !important;
    padding: 20px 40px;
  }

  .dashboardContainerA1InsideLabel {
    font-size: 16px !important;
  }

  .dashboardContainerA1InsideLabel2 {
    font-size: 36px !important;
  }

  .dashboardContainerA1InsideLabel3 {
    font-size: 36px !important;
  }

  /*       CONTAINER A2       */

  .dashboardContainerA2Content1 {
    padding: 15px;
  }

  .dashboardContainerA2Content1Label {
    font-size: 12px !important;
  }

  .dashboardContainerA2Content1Label2 {
    font-size: 12px !important;
  }

  .dashboardContainerA2Content1Img {
    height: 70px !important;
    width: 160px !important;
  }

  .dashboardContainerA2Content1Divider {
    margin: 0 30px;
  }

  .dashboardContainerA2Content1Count {
    font-size: 36px !important;
  }

  .dashboardContainerA2Content1Acc {
    font-size: 12px !important;
  }

  .dashboardContainerA2Content2 {
    padding: 15px;
  }

  /*       CONTAINER B       */

  .dashboardContainerB {
    width: 71.9em;
  }

  .dashboardContainerB1FormControl {
    margin-left: 250px !important;
    width: 110px !important;
  }

  .dashboardContainerB1FormControl Select {
    padding: 10px !important;
    height: 15px !important;
    font-size: 12px;
  }

  .dashboardContainerB1ContentCount {
    margin-top: 50px !important;
    font-size: 36px !important;
  }

  .dashboardContainerB2FormControl {
    margin-left: 150px !important;
    width: 100px !important;
  }

  .dashboardContainerB2FormControl Select {
    padding: 10px !important;
    height: 15px !important;
    font-size: 12px;
  }

  .dashboardContainerB2ContentCount {
    margin-top: 50px !important;
    font-size: 36px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .dashboardMasterContainer {
    margin: 30px 30px !important;
  }

  .dashboardLabel {
    font-size: 17px !important;
  }

  .dashboardContainerA {
    margin-top: 15px !important;
  }

  /*       CONTAINER A1       */

  .dashboardContainerA1 {
    width: 50em;
  }

  .dashboardContainerA1Label {
    padding: 15px 25px !important;
    font-size: 12px !important;
  }

  .dashboardContainerA1Content {
    padding: 20px 30px;
  }

  .dashboardContainerA1Inside {
    width: 150px !important;
    padding: 10px 30px;
  }

  .dashboardContainerA1Inside:not(:nth-child(4n + 1)) {
    margin-left: 10px !important;
  }

  .dashboardContainerA1InsideLabel {
    font-size: 12px !important;
  }

  .dashboardContainerA1InsideLabel2 {
    font-size: 28px !important;
  }

  .dashboardContainerA1InsideLabel3 {
    font-size: 28px !important;
  }

  .dashboardContainerA1InsideButton {
    font-size: 12px !important;
  }

  /*       CONTAINER A2       */

  .dashboardContainerA2 {
    margin-left: 10px;
  }

  .dashboardContainerA2Content1 {
    padding: 10px;
    margin-bottom: 10px;
  }

  .dashboardContainerA2Content1Label {
    font-size: 12px !important;
  }

  .dashboardContainerA2Content1Label2 {
    font-size: 12px !important;
    margin-top: 20px !important;
  }

  .dashboardContainerA2Content1Img {
    height: 60px !important;
    width: 130px !important;
  }

  .dashboardContainerA2Content1Divider {
    margin: 0 30px;
  }

  .dashboardContainerA2Content1Count {
    font-size: 28px !important;
  }

  .dashboardContainerA2Content1Acc {
    font-size: 9px !important;
  }

  .dashboardContainerA2Content2 {
    padding: 10px;
  }

  /*       CONTAINER B       */

  .dashboardContainerB {
    margin-top: 10px !important;
    width: 50em;
  }

  .dashboardContainerB1 {
    margin-right: 10px;
    padding: 15px;
  }

  .dashboardContainerB1FormControl {
    margin-left: 130px !important;
    width: 90px !important;
  }

  .dashboardContainerB1FormControl Select {
    padding: 5px 5px 5px 10px !important;
    height: 20px !important;
    font-size: 10px;
  }

  .dashboardContainerB1ContentCount {
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    font-size: 32px !important;
  }

  .dashboardContainerB2 {
    padding: 15px;
  }

  .dashboardContainerB2FormControl {
    margin-left: 50px !important;
    width: 130px !important;
  }

  .dashboardContainerB2FormControl Select {
    padding: 5px 5px 5px 10px !important;
    height: 20px !important;
    font-size: 10px;
  }

  .dashboardContainerB2ContentCount {
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    font-size: 32px !important;
  }
}

/*       purgecss end ignore       */

