.accountRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-left: 260px;
  height: 100%;
}

.accountContainerLeft {
  height: auto;
  min-height: 87vh;
  width: 22%;
  padding: 25px 90px;
  box-shadow: 1px -7px 4px 4px #ccc;
}

.accountBackButton {
  font-size: 14px !important;
  font-weight: 400;
  color: #444444 !important;
  margin-bottom: 28px !important;
  cursor: pointer;
  text-transform: capitalize !important;
}

.accountBackButton:hover {
  background-color: white !important;
  color: #c8932a !important;
}

.accountSideIcon {
  margin-right: 21px;
}

.accountSideButtonActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #256141;
  margin-bottom: 28px;
  cursor: pointer;
}

.accountSideButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin-bottom: 28px;
  cursor: pointer;
}

/* CONTENT RIGHT */

.accountContainerRight {
  height: 100%;
  width: 65%;
  padding: 47px 80px;
  /* border: 1px solid black; */
}

.accountItemHeader {
  font-size: 29px;
  font-weight: 600;
}

.accountItemTitle {
  font-size: 20px;
  font-weight: 600;
}

.accountContentTable {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: black;
  border-collapse: collapse;
}

.accountContentTable tr {
  padding-left: 20px;
  height: 68px;
}

.accountContentTable td {
  border-bottom: 1px solid #dadada !important;
}

.accountContentTable td:first-child {
  width: 390px;
  padding-right: 30px;
  font-weight: 600;
  color: #a4a4a4;
}

.accountOverviewEditButton {
  margin-top: 40px !important;
  height: 50px;
  width: 181px;
  border: 1px solid #dadada !important;
  color: #444444;
  font-size: 14px;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.accountEditAccountFieldContainer {
  display: flex;
  flex-direction: column;
}

.accountEditAccountTitle {
  font-size: 16px;
  font-weight: 600px;
}

.accountEditAccountEmailTextbox {
  width: 100% !important;
  font-size: 17px !important;
  font-weight: 400;
}

.accountEditAccountNumTextbox {
  width: 250px;
  font-size: 17px !important;
  font-weight: 400;
}

.accountEditAccountNumText {
  font-size: 17px !important;
  font-weight: 400;
}

.accountEditAccountSaveButton {
  margin-top: 40px !important;
  height: 50px;
  width: 181px;
  background-color: #256141 !important;
  color: white !important;
  font-size: 14px;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.accountEditAccountSaveButton:hover {
  background-color: #18442d !important;
}

@media only screen and (max-width: 1500px) {
  .accountContainerLeft {
    padding: 25px 50px;
  }

  .accountBackButton {
    font-size: 13px !important;
  }

  .accountSideIcon {
    font-size: 24px !important;
  }

  .accountSideButtonActive {
    font-size: 13px;
  }

  .accountSideButton {
    font-size: 13px;
  }

  /* CONTENT RIGHT */

  .accountContainerRight {
    width: 65%;
    padding: 47px 80px;
  }

  .accountItemHeader {
    font-size: 23px;
  }

  .accountItemTitle {
    font-size: 16px;
  }

  .accountContentTable {
    font-size: 12px;
  }

  .accountContentTable tr {
    padding-left: 20px;
    height: 55px;
  }

  .accountContentTable td:first-child {
    width: 310px;
  }

  .accountOverviewEditButton {
    height: 40px;
    width: 151px;
    font-size: 12px !important;
  }

  .accountEditAccountTitle {
    font-size: 13px;
  }

  .accountEditAccountEmailTextbox {
    height: 10px !important;
    font-size: 14px !important;
  }

  .accountEditAccountNumTextbox {
    height: 40px !important;
    font-size: 14px !important;
  }

  .accountEditAccountNumText {
    font-size: 14px !important;
  }

  .accountEditAccountSaveButton {
    height: 40px;
    width: 141px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .accountRoot {
    margin-top: 0;
    margin-left: 240px;
  }

  .accountContainerLeft {
    width: 24%;
    padding: 25px 45px;
  }

  .accountBackButton {
    font-size: 13px !important;
  }

  .accountSideIcon {
    font-size: 24px !important;
  }

  .accountSideButtonActive {
    font-size: 13px;
  }

  .accountSideButton {
    font-size: 13px;
  }

  /* CONTENT RIGHT */

  .accountContainerRight {
    width: 60%;
    padding: 47px 80px;
  }

  .accountItemHeader {
    font-size: 19px;
  }

  .accountItemTitle {
    font-size: 13px;
  }

  .accountContentTable {
    font-size: 11px;
  }

  .accountContentTable td:first-child {
    width: 250px;
  }

  .accountOverviewEditButton {
    height: 40px;
    width: 111px;
    font-size: 11px !important;
  }

  .accountEditAccountTitle {
    font-size: 12px;
  }

  .accountEditAccountEmailTextbox {
    height: 5px !important;
    font-size: 12px !important;
  }

  .accountEditAccountNumTextbox {
    width: 200px;
    height: 35px !important;
    font-size: 12px !important;
  }

  .accountEditAccountNumText {
    font-size: 12px !important;
  }

  .accountEditAccountSaveButton {
    height: 40px;
    width: 111px;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .accountContainerLeft {
    width: 25%;
    padding: 25px 40px;
  }

  .accountBackButton {
    font-size: 10px !important;
  }

  .accountSideIcon {
    font-size: 20px !important;
  }

  .accountSideButtonActive {
    font-size: 10px;
  }

  .accountSideButton {
    font-size: 10px;
  }

  /* CONTENT RIGHT */

  .accountContainerRight {
    width: 65%;
    padding: 47px 80px;
  }

  .accountItemHeader {
    font-size: 19px;
  }

  .accountItemTitle {
    font-size: 12px;
  }

  .accountContentTable {
    font-size: 10px;
  }

  .accountContentTable td:first-child {
    width: 240px;
  }

  .accountOverviewEditButton {
    height: 35px;
    width: 111px;
    font-size: 10px !important;
  }

  .accountEditAccountTitle {
    font-size: 11px;
  }

  .accountEditAccountEmailTextbox {
    height: 3px !important;
    font-size: 11px !important;
  }

  .accountEditAccountNumTextbox {
    width: 170px !important;
    height: 33px !important;
    font-size: 11px !important;
  }

  .accountEditAccountNumText {
    font-size: 11px !important;
  }

  .accountEditAccountSaveButton {
    height: 35px;
    width: 111px;
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .accountRoot {
    margin-top: 0;
    margin-left: 0;
  }
}
