.fundEditRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.fundEditContainerLeft {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 85vh;
  width: 55%;
  padding: 60px 70px 30px 90px;
  /* border: 1px solid black; */
}

.fundEditContainerRight {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: 45%;
  padding: 127px 90px 90px 0;
  /* overflow-y: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none; */
  /* border: 1px solid black; */
}

/* .fundEditContainerRight::-webkit-scrollbar {
    width: 0 !important;
} */

.fundEditHeader {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 30px;
}

.fundEditHeader2 {
  font-size: 21px;
  font-weight: 600;
}

.fundEditTitle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.fundEditTextBox {
  padding: 20px !important;
  font-size: 14px !important;
  height: 10px !important;
}

.fundEditFormControl Select {
  height: 28px;
  font-size: 14px;
}

.fundEditDescription {
  font-size: 14px !important;
}

.fundEditRadioButtonActive {
  border: 2px solid #256141;
  border-radius: 5px;
  padding-right: 35px;
  margin-left: 1px !important;
}

.fundEditRadioButton {
  border: 2px solid transparent;
  border-radius: 5px;
  padding-right: 35px;
  margin-left: 1px !important;
}

.fundEditCancelButton {
  margin-top: 10px !important;
  margin-left: 90px !important;
  background-color: transparent !important;
  height: 50px;
  width: 150px;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundEditAddButton {
  float: right;
  margin-top: 10px !important;
  margin-right: 90px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 50px;
  width: 150px;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundEditAddButton:hover {
  background-color: #1b4930 !important;
}

.fundEditLoading {
  float: right !important;
  margin-top: 10px;
  margin-right: 140px;
}

@media only screen and (max-width: 1500px) {
  .fundEditContainerLeft {
    width: 53%;
    padding: 50px 50px 20px 70px;
  }

  .fundEditContainerRight {
    /* height: 859px; */
    width: 48%;
    padding: 112px 70px 20px 0;
  }

  .fundEditHeader {
    font-size: 23px;
  }

  .fundEditHeader2 {
    font-size: 17px;
  }

  .fundEditTitle {
    font-size: 12px;
  }

  .fundEditTextBox {
    padding: 17px !important;
    font-size: 12px !important;
  }

  .fundEditFormControl Select {
    height: 23px;
    font-size: 12px;
  }

  .fundEditDescription {
    font-size: 12px !important;
  }

  .fundEditRadioButtonText {
    font-size: 14px !important;
  }

  .fundEditCancelButton {
    margin-left: 70px !important;
    height: 45px;
    width: 130px;
    font-size: 12px !important;
  }

  .fundEditAddButton {
    margin-right: 70px !important;
    height: 45px;
    width: 130px;
    font-size: 12px !important;
  }

  .fundEditLoading {
    margin-right: 130px;
  }
}

@media only screen and (max-width: 1366px) {
  .fundEditRoot {
    margin-left: 240px !important;
    margin-top: 0 !important;
  }

  .fundEditContainerLeft {
    width: 53%;
    padding: 50px 50px 20px 50px;
  }

  .fundEditContainerRight {
    /* height: 833px; */
    width: 48%;
    padding: 107px 50px 20px 0;
  }

  .fundEditHeader {
    font-size: 20px;
  }

  .fundEditHeader2 {
    font-size: 16px;
  }

  .fundEditTitle {
    font-size: 11px;
  }

  .fundEditTextBox {
    padding: 14px !important;
    font-size: 11px !important;
  }

  .fundEditFormControl Select {
    height: 16px;
    font-size: 11px;
  }

  .fundEditDescription {
    font-size: 11px !important;
  }

  .fundEditRadioButtonText {
    font-size: 13px !important;
  }

  .fundEditCancelButton {
    margin-left: 50px !important;
    height: 40px;
    width: 110px;
    font-size: 11px !important;
  }

  .fundEditAddButton {
    margin-right: 50px !important;
    height: 40px;
    width: 110px;
    font-size: 11px !important;
  }

  .fundEditLoading {
    margin-right: 80px;
  }
}

@media only screen and (max-width: 1060px) {
  .fundEditRoot {
    margin-left: 0 !important;
  }
}

.upload-box {
  margin-top: 20px;
  border: 1px dashed #9f9f9f;
  box-sizing: border-box;
  border-radius: 5px;
  height: 86px;
  position: relative;
  width: 70%;
  text-align: center;
  width: 100%;
}

.upload-file {
  display: inline-block;
  position: absolute;
  z-index: 999;
  right: 0%;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-box span {
  position: relative;
  top: 30px;
}

.custom-list-upload {
  padding: 20px 0px;
  box-shadow: none !important;
  border-bottom: 1px solid #dadada;
}

.custom-chip-upload {
  background: none !important;
  font-weight: bold;
  color: #256141 !important;
}

.label-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 10px;
}
