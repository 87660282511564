.fundListRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.fundListPaper {
  height: auto;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  padding: 49px 50px 40px 50px;
  /* border: 1px solid black; */
}

.fundListPaper2 {
  height: 100%;
  width: 100%;
  padding: 60px 45px 40px 0;
  /* border: 1px solid black; */
}

.fundListTableWidth {
  width: 100%;
}

.fundListTitleText {
  font-size: 29px;
}

.fundListDescription {
  font-size: 14px;
}

.fundListAddButton {
  width: 181px;
  height: 50px;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  margin-top: 28px !important;
}

.fundListAddButton:hover {
  background-color: #a67717 !important;
}

.fundListAttachButton {
  width: 181px;
  height: 50px;
  color: #c8932a !important;
  background-color: white !important;
  border: 1px solid #c8932a !important;
  text-transform: capitalize !important;
  margin-top: 18px !important;
}

.fundListAttachButton:hover {
  background-color: rgba(209, 209, 209, 0.726) !important;
}

.fundListTextField {
  width: 240px;
  margin-bottom: 25px !important;
  float: right;
  text-transform: capitalize !important;
}

.fundListPagination {
  color: "#444444";
  font-weight: bold;
}

.fundListIconBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ed5555 !important;
  border-radius: 100% !important;
  height: 65px;
  width: 65px;
}

.fundListIcon {
  margin-top: 9px;
  color: white !important;
  font-size: 40px !important;
}

.fundListDialogTitle {
  font-size: 20px !important;
  font-weight: 600;
}

.fundListDialogName {
  font-size: 24px !important;
  font-weight: 600;
  color: #256141 !important;
}

.fundListDialogDeleteBtn {
  background-color: #ed5555 !important;
  color: white !important;
  height: 55px;
  margin: 0 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundListDialogDeleteBtn:hover {
  background-color: #bd3b3b !important;
}

.fundListDialogCancelBtn {
  height: 55px;
  margin: 5px 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fundListLoading {
  margin-left: 45% !important;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1500px) {
  .fundListPaper {
    padding: 40px 40px 40px 40px;
  }

  .fundListPaper2 {
    padding: 50px 31px 40px 0;
  }

  .fundListTitleText {
    font-size: 25px;
  }

  .fundListDescription {
    font-size: 12px;
  }

  .fundListAddButton {
    width: 150px;
    height: 40px;
    font-size: 12px !important;
  }

  .fundListAttachButton {
    width: 150px;
    height: 40px;
    font-size: 12px !important;
  }

  .fundListTextField {
    width: 220px;
  }

  .fundListIconBackground {
    height: 55px;
    width: 55px;
  }

  .fundListIcon {
    margin-top: 8px;
    font-size: 35px !important;
  }

  .fundListDialogTitle {
    font-size: 17px !important;
  }

  .fundListDialogName {
    font-size: 21px !important;
  }

  .fundListDialogDeleteBtn {
    height: 50px;
    font-size: 12px !important;
  }

  .fundListDialogCancelBtn {
    height: 50px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .fundListRoot {
    margin-left: 240px !important;
    margin-top: 0 !important;
  }

  .fundListPaper {
    padding: 40px 40px 40px 40px;
  }

  .fundListPaper2 {
    padding: 50px 31px 40px 0;
  }

  .fundListTitleText {
    font-size: 24px;
  }

  .fundListDescription {
    font-size: 12px;
  }

  .fundListAddButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .fundListAttachButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .fundListTextField {
    width: 230px;
  }

  .fundListIconBackground {
    height: 45px;
    width: 45px;
  }

  .fundListIcon {
    margin-top: 6px;
    font-size: 30px !important;
  }

  .fundListDialogTitle {
    font-size: 14px !important;
  }

  .fundListDialogName {
    font-size: 18px !important;
  }

  .fundListDialogDeleteBtn {
    height: 45px;
    font-size: 11px !important;
  }

  .fundListDialogCancelBtn {
    height: 45px;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .fundListRoot {
    margin-left: 0 !important;
  }

  .fundListPaper {
    padding: 40px 40px 40px 40px;
  }

  .fundListPaper2 {
    padding: 50px 31px 40px 0;
  }

  .fundListTitleText {
    font-size: 24px;
  }

  .fundListDescription {
    font-size: 12px;
  }

  .fundListAddButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .fundListAttachButton {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
  }

  .fundListTextField {
    width: 230px;
  }
}
