.navroot {
  display: flex;
  font-family: "Poppins", sans-serif;
  position: static;
}

.navappBar {
  background-color: white !important;
  width: 100%;
  padding: 5px;
}

.navmenuButton {
  display: none !important;
  color: black !important;
}

.navdrawerPaper {
  width: 260px;
  display: block !important;
  background-color: #256141 !important;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.navdrawerPaper::-webkit-scrollbar {
  width: 0 !important;
}

.navdrawerPaper2 {
  width: 260px;
  display: none !important;
  background-color: #256141 !important;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.navdrawerPaper2::-webkit-scrollbar {
  width: 0 !important;
}

.navavatar {
  height: 50px !important;
  width: 50px !important;
  left: 96%;
}

.navdashboardLogo {
  margin: -45px 0 50px 52px;
  width: 157px;
  height: 79px;
}

.navmenuPadding {
  margin-left: 15px !important;
}

.navmenuColor {
  color: #a7c2b4 !important;
}

.navmenuColorActive {
  color: #ffffff !important;
}

.navmenuFontSize {
  font-size: 14px !important;
}

@media only screen and (max-width: 1366px) {
  .navappBar {
    padding: 0;
  }

  .navavatar {
    height: 40px !important;
    width: 40px !important;
  }

  .navdashboardLogo {
    margin: -45px 0 50px 50px;
    width: 140px;
    height: 67px;
  }

  .navmenuPadding {
    margin-left: 15px !important;
  }

  .navmenuFontSize {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .navmenuButton {
    display: block !important;
  }

  .navdrawerPaper {
    display: none !important;
  }

  .navdrawerPaper2 {
    display: block !important;
  }

  .navnotif {
    left: 87%;
  }

  .navavatar {
    left: 90%;
  }
}

@media only screen and (max-width: 960px) {
  .navnotif {
    left: 83%;
  }

  .navavatar {
    left: 87%;
  }
}

@media only screen and (max-width: 660px) {
  .navnotif {
    left: 71%;
  }

  .navavatar {
    left: 77%;
  }
}
