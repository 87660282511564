.userListRoot {
  flex-grow: 1;
  margin-left: 280px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.userListBody {
  padding: 20px;
  position: relative;
}

.userListpaper {
  padding: 30px;
  /* border: 1px solid black; */
}

.userListpaper2 {
  height: 100%;
  width: 100%;
  padding: 60px 45px 40px 0;
  /* border: 1px solid black; */
}

.userListtableWidth {
  width: 100%;
}

.userListtitleText {
  font-size: 29px;
}

.userListdescription {
  font-size: 14px;
}

.userListaddButton {
  width: 160px;
  height: 45px;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  margin-top: 28px !important;
  margin-bottom: 28px !important;
  position: absolute !important;
  right: 20px !important;
  top: 80px !important;
  padding: 10px !important;
}

.userListaddButton:hover {
  background-color: #a67717 !important;
}

.userListlabelUser {
  font-size: 16px;
  margin-top: 69px !important;
}

.userListformControl {
  margin-top: 12px;
  width: 270px;
}

.userListformControl Select {
  height: 25px;
  font-size: 15px;
}

.userListtextField {
  width: 240px;
  margin-bottom: 25px !important;
  float: right;
  text-transform: capitalize !important;
}

.userListpagination {
  color: "#444444";
  font-weight: bold;
}

.userListdialogLabel {
  font-size: 20px;
  font-weight: bold;
}

.userListdialogLabel2 {
  font-size: 24px;
  font-weight: bold;
  color: #256141;
}

.userListdialogLabel3 {
  margin-top: 15px;
  font-size: 14px;
}

.userListdialogLabel4 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.userListtextboxs {
  width: 353px;
  font-size: 16px;
}

.userListdeleteBtn {
  width: 380px;
  height: 50px;
  margin: 0 24px 10px 24px;
  background-color: #ed5555;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
}

.userListdeleteBtn:hover {
  background-color: #b83535;
}

.userListcancelBtn {
  font-size: 14px;
  width: 380px;
  height: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
  margin-left: 24px;
}

@media only screen and (max-width: 1500px) {
  .userListpaper {
    padding: 40px 40px 40px 70px;
  }

  .userListpaper2 {
    padding: 50px 31px 40px 0;
  }

  .userListtitleText {
    font-size: 25px;
  }

  .userListdescription {
    font-size: 11px;
  }

  .userListaddButton {
    width: 140px;
    height: 40px;
  }

  .userListlabelUser {
    font-size: 13px;
  }

  .userListformControl {
    width: 240px;
  }

  .userListformControl Select {
    font-size: 14px;
  }

  .userListtextField {
    width: 230px;
  }
}

@media only screen and (max-width: 1366px) {
  .userListRoot {
    /* margin-left: 240px !important; */
    margin-top: 0 !important;
  }

  .userListpaper {
    padding: 40px 40px 40px 30px;
  }

  .userListpaper2 {
    padding: 50px 31px 40px 0;
  }

  .userListtitleText {
    font-size: 25px;
  }

  .userListdescription {
    font-size: 11px;
  }

  .userListaddButton {
    width: 120px;
    height: 35px;
    font-size: 11px !important;
  }

  .userListlabelUser {
    font-size: 12px;
    margin-top: 55px !important;
  }

  .userListformControl {
    width: 220px;
  }

  .userListformControl Select {
    font-size: 13px;
    height: 15px;
  }

  .userListtextField {
    width: 220px;
  }
}

@media only screen and (max-width: 1060px) {
  .userListRoot {
    margin-left: 0px !important;
    margin-top: 8px !important;
  }

  .userListpaper {
    padding: 40px 40px 40px 40px;
  }

  .userListpaper2 {
    padding: 50px 31px 40px 0;
  }

  .userListtitleText {
    font-size: 20px;
  }

  .userListdescription {
    font-size: 12px;
  }

  .userListaddButton {
    width: 130px;
    height: 35px;
    font-size: 12px;
  }

  .userListlabelUser {
    font-size: 12px;
  }

  .userListformControl {
    width: 230px;
  }

  .userListformControl Select {
    font-size: 13px;
  }

  .userListtextField {
    width: 230px;
  }
}
