.addUserRoot {
  flex-grow: 1;
  width: 820px;
  margin-left: 780px !important;
  margin-top: 50px !important;
  display: flex;
  flex-direction: column;
  align-items: "center";
  font-family: "Poppins", sans-serif;
}

.addUserlabels {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.addUserlabels1 {
  font-size: 29px !important;
  font-weight: bold !important;
}

.addUserlabels2 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.addUsertextboxs {
  width: 242px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.addUsertextboxs1 {
  width: 202px !important;
  font-size: 16px !important;
}

.addUsertextboxs1Text {
  font-size: 16px !important;
}

.addUsertextboxs2 {
  width: 522px !important;
  font-size: 16px !important;
}

.addUserselectboxs {
  width: 223px !important;
  font-size: 16px !important;
}

.addUseraddButton {
  right: -85px !important;
  width: 165px !important;
  height: 45px !important;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

.addUseraddButton:hover {
  background-color: #a67717 !important;
}

.MuiCircularProgress-colorPrimary {
  color: "#1AB071" !important;
}

.addUseralertWidth {
  width: 531px !important;
  margin-top: 20px !important;
}

@media only screen and (max-width: 1500px) {
  .addUserRoot {
    width: 630px;
    margin-left: 40% !important;
    margin-top: 40px !important;
  }

  .addUserlabels {
    font-size: 14px !important;
  }

  .addUserlabels1 {
    font-size: 25px !important;
  }

  .addUserlabels2 {
    font-size: 19px !important;
  }

  .addUsertextboxs {
    width: 180px !important;
    font-size: 13px !important;
  }

  .addUsertextboxs1 {
    width: 161px !important;
    font-size: 13px !important;
  }

  .addUsertextboxs1Text {
    font-size: 13px !important;
  }

  .addUsertextboxs2 {
    width: 395px !important;
    font-size: 13px !important;
  }

  .addUserselectboxs {
    width: 163px !important;
    font-size: 13px !important;
  }

  .addUseraddButton {
    right: -55px !important;
    width: 130px !important;
    height: 40px !important;
    font-size: 12px !important;
  }

  .addUseralertWidth {
    width: 405px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .addUserRoot {
    width: 630px !important;
    margin-left: 580px !important;
    margin-top: 40px !important;
  }

  .addUserlabels {
    font-size: 14px !important;
  }

  .addUserlabels1 {
    font-size: 25px !important;
  }

  .addUserlabels2 {
    font-size: 19px !important;
  }

  .addUsertextboxs {
    width: 180px !important;
    font-size: 13px !important;
  }

  .addUsertextboxs1 {
    width: 161px !important;
    font-size: 13px !important;
  }

  .addUsertextboxs1Text {
    font-size: 13px !important;
  }

  .addUsertextboxs2 {
    width: 395px !important;
    font-size: 13px !important;
  }

  .addUserselectboxs {
    width: 163px !important;
    font-size: 13px !important;
  }

  .addUseraddButton {
    right: -70px !important;
    width: 120px !important;
    height: 37px !important;
    font-size: 12px !important;
  }

  .addUseralertWidth {
    width: 405px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .addUserRoot {
    width: 630px !important;
    margin-left: 300px !important;
    margin-top: 40px !important;
  }
}

#parent-form {
  width: 540px;
}
