.body-content {
  padding: 30px;
}

.transaction-list {
  padding: 20px;
  position: relative;
}

.tbl-container {
  padding-left: 40px;
}

.tbl-primary-list {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.tbl-primary-list thead {
  background: #256141;
  color: white;
}

.tbl-primary-list thead th {
  padding: 20px;
}

.tbl-primary-list tbody td {
  padding: 20px;
}

.tbl-primary-list tbody tr:nth-child(even) {
  background: white;
}

.tbl-primary-list tbody tr:nth-child(odd) {
  background: rgba(37, 97, 65, 0.1);
}

.csv_link {
  position: absolute !important;
  right: 20px !important;
  top: 80px !important;
  color: white !important;
}

/* .csv_link span a {
    text-decoration: none !important;
    color: white !important;
} */
