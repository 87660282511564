.approve-button {
  width: 165px;
  height: 45px;
  color: #ffffff !important;
  background-color: #c8932a !important;
  text-transform: capitalize !important;
  font-size: 16px;
}

.approve-button:hover {
  background-color: #a67717 !important;
}
