.categoriesEditRoot {
  flex-grow: 1;
  margin-left: 260px !important;
  margin-top: 10px !important;
  font-family: "Poppins", sans-serif;
}

.categoriesEditContainer {
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 45%;
  /* border: 1px solid black; */
}

.categoriesEditHeader {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.categoriesEditTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.categoriesEditTextBox {
  padding: 20px !important;
  font-size: 16px !important;
}

.categoriesEditDescription {
  font-size: 16px !important;
}

.categoriesEditCancelButton {
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.categoriesEditEditButton {
  float: right !important;
  border-radius: 5px !important;
  background-color: #256141 !important;
  color: white !important;
  height: 45px;
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.categoriesEditEditButton:hover {
  background-color: #18422b !important;
}

.categoriesEditLoading {
  float: right !important;
  margin-right: 50px;
}

@media only screen and (max-width: 1500px) {
  .categoriesEditContainer {
    margin-left: 22%;
    width: 51%;
  }

  .categoriesEditHeader {
    font-size: 24px;
  }

  .categoriesEditTitle {
    font-size: 14px;
  }

  .categoriesEditTextBox {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .categoriesEditDescription {
    font-size: 14px !important;
  }

  .categoriesEditCancelButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }

  .categoriesEditEditButton {
    height: 40px;
    width: 110px;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .categoriesEditRoot {
    margin-left: 260px !important;
    margin-top: -20px !important;
  }

  .categoriesEditContainer {
    margin-left: 21%;
    width: 55%;
    /* border: 1px solid black; */
  }

  .categoriesEditHeader {
    font-size: 22px;
  }

  .categoriesEditTitle {
    font-size: 12px;
  }

  .categoriesEditTextBox {
    padding: 12px !important;
    font-size: 12px !important;
  }

  .categoriesEditDescription {
    font-size: 12px !important;
  }

  .categoriesEditCancelButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }

  .categoriesEditEditButton {
    height: 35px;
    width: 110px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1060px) {
  .categoriesEditRoot {
    margin-left: 0 !important;
    margin-top: -20px !important;
  }
}
